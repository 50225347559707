import {useMemo} from "react";
import {ApiSelect} from "react-admin-base-bootstrap";
import {useIntl} from "react-intl";

const REASONS = [
    [
        'Körperpflege',
        [
            'Trachealkanüle/ Stomaversorgung',
            'Grobreinigung mit abtrocknen',
            'gründliche Säuberung',
            'Waschen',
            'Ganzkörperwäsche',
            'Haare waschen',
            'Gesicht waschen',
            'Baden/Duschen',
            'Rasieren',
            'Kämmen',
            'Hand- u. Fußpflege, Kürzen der Nägel und Entfernen der Hornhaut'
        ]
    ],

    [
        'Zahnpflege',
        [
            'herrichten von Zahnbürste mit Zahnpasta und Mundspülwasser',
            'Zahnprothesen herrausnehmen/ einsetzen',
            'Auskleiden',
            'Ankleiden'
        ]
    ],
    
    [
        'Tagespflege/ Kosmetik', 
        [
            'Auftragen von Tagescreme',
            'Auftragen von Nachtcreme',
            'Auftragen von Bodylotion',
            'Auftragen von Wundschutzcreme'
        ]
    ],

    [
        'Ernährung',
        [
            'Hilfe bei Nahrungsaufnahme',
            'Essenzubereitung/-einnahme',
            'Zwischenmahlzeiten',
            'Frühstück',
            'Mittagessen',
            'Abendessen',
            'Mundgerechte Zubereitung der Nahrung',
            'Getränk zubereiten, servieren'
        ]
    ],
    
    [
        'Mobilität',
        [
            'Aufstehen/Zubettgehen',
            'Anlegen von Prothesen',
            'Lagern innerhalb und außerhalb des Bettes',
            'Gehen/Bewegen',
            'Umlagern',
            'Treppen steigen',
            'Verlassen/ Wiederaufsuchen der Wohnung'
        ]
    ],

    [
        'Rollstuhl',
        [
            'Rollstuhl bereitstellen',
            'Umsetzen auf Rollstuhl',
            'Aufstehen vom Rollstuhl'
        ]
    ],
    
    [
        'Hauswirtschaftliche Versorgung',
        [
            'Reinigen der Wohnung',
            'Kochen/ Speisenzubereitung',
            'Einkaufen',
            'Abwaschen/ Spülen',
            'Wechseln und Waschen der Wäsche',
            'Belüften der Wohnung'
        ]
    ],
    
    [
        'Freizeit',
        [
            'Ausflüge: Konzert, Kino, Thater, Cafe',
            'Spaziergänge durchführen',
            'Arztliche Sprechstunden erledigen',
            'Therapietermine erledigen',
            'Behörden Angelegenheiten durchführen',
            'Gesellschaftsspiele',
            'Basteln',
            'Freunde besuchen',
            'Shopping',
            'Religion ausüben'
        ]
    ]
];

export default function AssistantPlanTitleSelect({value, onChange}) {
    const intl = useIntl();

    const options = useMemo(() => REASONS.map(([name, values]) => ({ label: name, options: values.map(c => ({ id: c, name: c }))})), [intl]);

    return <ApiSelect
        value={value && {id: value, name: value}}
        onChange={a => onChange((a && a.id) || null)}
        staticOptions={options}
    />;
}
