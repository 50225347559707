import React from 'react';
import {Card, CardBody} from "reactstrap";
import QuizEntity from "./QuizEntity";
import {Breadcrumb} from "react-admin-base-front";
import {Actions, ActionsColumn, Column, CRUD, IdColumn, Relative, RowRenderer} from 'react-admin-base-bootstrap';
import {FormattedMessage} from "react-intl";

export default function Users() {
    return <Breadcrumb
        title="Module"
        data={
            [
                {
                    href: '/quiz',
                    name: <FormattedMessage id="MY_PROFILE"/>
                }
            ]
        }
    >
        <CRUD url="/quiz" Component={QuizEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column className="min">Sprache</Column>
                    <Column sort="name">Name der Module</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="text-center">
                        { row.language && ((row.language.icon && <Relative><img style={{ width: '24px', height: '24px' }} src={row.language.icon.access_url} alt={row.language.name} /></Relative>) || row.language.name) }
                    </td>
                    <td>{row.name}</td>
                    <Actions
                        edit={"/quiz/" + row.id + '/edit'}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
