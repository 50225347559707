
import React, { useMemo } from 'react';
import { ValidatorOptionProvider } from 'react-admin-base';

export default function ValidatorSettings({ children, messages }) {
    const settings = useMemo(() => ({
        messages: {
            required: 'Dieses Feld ist erforderlich.',
            ...(messages || '')
        }
    }), [messages]);

    return <ValidatorOptionProvider value={settings}>
        { children }
    </ValidatorOptionProvider>
}
