
import {Link, useParams } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {Alert, Button, Col, Container, Form, Input, Row, Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import React, {useCallback, useState, useReducer, useEffect} from "react";
import {OptionSelectPortal} from "../../Components/Selects";
import {useAuth, useFetch, ValidatorProvider} from "react-admin-base";
import {Validator} from "react-admin-base-bootstrap";

export default function Quiz({ id }) {
    const [ quiz, loading ] = useFetch("/quiz/" + id);
    const [ page, setPage ] = useReducer((a, b) => {
        window && window.parent && window.parent.postMessage('pagechan', '*');
        return b;
        }, 0);
    const [ answers, setAnswers ] = useState({});
    const [ contact, setContact ] = useState({});
    const [ session, setSession ] = useState();

    const [ api ] = useAuth();

    const submit = useCallback(async function(e) {
        const req = {
            ...contact,
            quiz: {
                id: quiz.id
            },
            answers: Object.entries(answers)
            .map(([ id, [ option, number ] ]) => ({
                question: {
                    id
                },
                option: {
                    id: option
                },
                number
            }))
        };

        const data = await api.tokenized.post('/session', req);

        if (data.status === 200) {
            setSession(data.data);
            window && window.parent && window.parent.postMessage('pagechan', '*');
        }
    }, [quiz, answers, contact, setSession]);

    const next = useCallback(function(e) {
        e.preventDefault();

        if (page !== quiz.pages.length - 1) {
            setPage(page + 1);
        } else if(page === quiz.pages.length - 1) {
            submit();
        }
    }, [page, setPage, quiz, submit]);

    useEffect(function() {
        function fnc(e) {
            if (e.keyCode === 86 && e.altKey) {
                setPage(quiz.pages.length - 1);

                const answers = quiz.pages
                .reduce((a, b) => a.concat(b.questions), [])
                .reduce((a, b) => ({ ...a, [b.id]: [b.option_group.values[0].id, 0] }), {});

                console.log(answers);
                setAnswers(answers);
            }
        }

        document.addEventListener('keyup', fnc);
        return () => document.removeEventListener('keyup', fnc);
    }, [quiz, setPage, setAnswers]);

    if (!quiz)
        return null;

    console.log(answers);

    return <>
        { session ? <FinalPage
            session={session}
            onNewForm={() => {
                setPage(0);
                setSession(null);
                setAnswers({});
                setContact({});
            }}
        /> : <Container>
            <QuizPages
                index={page}
                setIndex={setPage}
                modules={quiz.pages}
            />

            <ValidatorProvider>
                <Form onSubmit={next}>
                    <QuizModule
                        index={page}
                        module={quiz.pages[page]}
                        value={answers}
                        setValue={setAnswers}
                    />

                    <Row>
                        <Col md="6">
                            { page !== 0 && <Button type="button" block outline color="danger" onClick={() => setPage(page - 1)}>
                                <FormattedMessage id="BACK" />
                            </Button> }
                        </Col>
                        <Col md="6">
                            { page !== quiz.pages.length - 1 && <Button type="submit" block color="primary">
                                <FormattedMessage id="NEXT" />
                            </Button> }
                            { page === quiz.pages.length - 1 && <Button type="submit" block color="primary">
                                <FormattedMessage id="SEND" />
                            </Button> }
                        </Col>
                    </Row>
                </Form>
            </ValidatorProvider>
        </Container> }
    </>;
}

function FinalPage({ session, onNewForm }) {
    return <Container>
        <div className="text-center my-5">
            <h1><FormattedMessage id="SUCCESS_TITLE" /></h1>
            <div className="my-3">
                <FormattedMessage id="SUCCESS_DESC" values={{ br: <br /> }} />
            </div>
            <div className="my-3">
                <Link to="/" className="btn btn-primary">
                    <FormattedMessage id="SUCCESS_BUTTON" values={{ br: <br /> }} />
                </Link>
            </div>
        </div>
    </Container>;
}

function QuizPages({ index, setIndex, modules }) {
    return <ul className="modules">
        { modules.map((a, i) => <li key={i} className={ index === i ? "active" : index > i ? "past" : "future" }>
            <span onClick={setIndex.bind(null, i)}>
                <FormattedMessage id="MODULE" values={{ index: i + 1 }} />
            </span>
        </li>) }
    </ul>
}

function QuizModule({ index, module, value, setValue }) {
    const [ descActive, setDescActive ] = useState(false);

    return <div>
        <Row>
            <Col>
                <h1><FormattedMessage id="MODULE" values={{ index: index + 1 }} />{module.title && (" - " + module.title)}</h1>
            </Col>
            { module.description && <Col md="auto" className="align-self-center">
                <a href="#" className={descActive ? "desc-active" : "desc-disabled"} onClick={e => {
                    e.preventDefault();
                    setDescActive(!descActive);
                }}>
                    <FormattedMessage id={descActive ? "HIDE_DESCRIPTION" : "SHOW_DESCRIPTION"} />
                </a>
            </Col> }
        </Row>

        { descActive && module.description && <div
            className="mt-1 mb-3"
            dangerouslySetInnerHTML={{ __html: module.description || '<b>test</b>' }}
        /> }

        {
            module.questions
            .map((a,i) => <Question
                moduleIndex={index}
                questionIndex={i}
                key={a.id}
                question={a}
                answer={value && value[a.id]}
                setAnswer={v => setValue({ ...(value || {}), [a.id]: v })}
            />)
        }
    </div>;
}

function Question({ moduleIndex, questionIndex, question, answer, setAnswer }) {
    const [a] = answer || [];
    const [ open, setOpen ] = useState(false);

    return <div className={"question " + (a ? 'not-empty' : 'empty')}>
        <Row>
            <Col md="6">
                {moduleIndex+1}.{questionIndex+1}. { question.question }
            </Col>
            <Col xs="auto">
                <a href="#" style={{ fontSize: '1.25em' }} onClick={e => {
                    e.preventDefault();
                    setOpen(!open);
                }}>
                    <i className="fas fa-info-circle" />
                </a>
            </Col>
            <Col>
                <AnswerSelect
                    ids={question.id}
                    options={question.option_group}
                    value={answer}
                    setValue={setAnswer}
                />
            </Col>
        </Row>
        { open && <Alert color="info" className="my-3">
            <div dangerouslySetInnerHTML={{ __html: question.description }} />
        </Alert> }
    </div>;
}

function AnswerSelect({ ids, value, setValue, options }) {
    const [ id, number ] = value || [];

    const selectedVal = value && options.values.find(a => a.id === +id);

    return <Row>
        <Col>
            <Validator name={ids+".option"} type="required">
                <OptionSelectPortal
                    value={id}
                    setValue={id => setValue([id || null, number || 0])}
                    options={options}
                />
            </Validator>
        </Col>
        { selectedVal && selectedVal.ask_number && <Col md="3">
            <Validator name={ids+".number"} type="required|numeric|min:1,num">
                <Input type="number" value={number} onChange={a => setValue([id, a.currentTarget.value])} min="0" max="99" />
            </Validator>
        </Col> }
    </Row>;
}
