import {useUser} from "./UserProvider";
import {useFetch} from "react-admin-base";
import {Navigate} from "react-router-dom";
import { useEffect, useState } from "react";

export default function SessionGuard({ children }) {
    const user = useUser();
    const [ sessions ] = useFetch(user.type === 8 && '/session?itemPerPage=1');

    if (user.type === 8) {
        if (!sessions)
            return null;

        if (sessions.data.length === 0) {
            return <Navigate to="/session/first" replace />;
        }
    }

    return <IframeGuard>
        { children }
    </IframeGuard>;
}

function IframeGuard({ children }) {
    const [ isFrame, setIsFrame ] = useState(true);
    
    useEffect(function() {
        if (window !== window.parent) {
            window.parent.location = window.location;
        } else {
            setIsFrame(false);
        }
    }, [setIsFrame]);
    
    return isFrame === false && children;
}