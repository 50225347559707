import React, { useState } from 'react';
import {Actions,CRUD, ActionsColumn, Column, ExcelExportButton, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import SessionEntity, {labels} from "./SessionEntity";
import SessionTableButton from "./SessionTable";
import {FormattedDate, FormattedMessage} from "react-intl";
import { Link, Route, Routes } from 'react-router-dom';
import AssistantPlanEntity from './AssistantPlanEntity';
import { useUser } from '../../Components/UserProvider';
import {SessionApproveButton} from "./SessionApprove";
import AttachmentsEntity from './AttachmentsEntity';
import BedafsrechnerPage from './BedafsrechnerPage';
import { useRefresh } from 'react-admin-base';
import { ChatBoxModal } from '../Message/ChatBox';
import { Badge, Button } from 'reactstrap';
import { InlineEditLink } from '../../Components/InlineEditButton';
import UserEntity from '../User/UserEntity';

function SessionStatus({ value }) {
    switch (value) {
        case 1:
            return <span className="text-warning">Anfrage</span>;
        case 2:
            return <span className="text-info">in Prüfung</span>;
        case 4:
            return <span className="text-success">im Bearbeitung</span>;
        case 3:
            return <span className="text-danger">Abgelehnt</span>;
    }
    
    return null;
}

export function SessionChatButton({session, size}) {
    const [open, setOpen] = useState(false);
    const update = useRefresh();

    const user = useUser();
    if (user.type === 256)
        return null;
    
    const isAdmin = user.type >= 128;
    const unRead = session["unread_" + (isAdmin ? 'admin' : 'user')];
    
    return <>
        {open && <ChatBoxModal id={session.id} onClose={_ => {
            setOpen(false);
            update();
        }}/>}
        <Button onClick={_ => setOpen(true)} className="ms-1" size={size} outline type="button" color="info"><i className="fas fa-envelope"/> <FormattedMessage id="MESSAGES" /> {unRead > 0 &&
            <Badge color="danger" pill>{unRead}</Badge>}</Button>
    </>;
}

export default function Sessions() {
    const user = useUser();

    const defaultParams = {
        sort: 'id',
        desc: true
    };

    return <Breadcrumb
        data={
            [
                {
                    href: '/session',
                    name: <FormattedMessage id="MODULES"/>
                }
            ]
        }
    >
        <CRUD url="/session" defaultParams={defaultParams}  noAdd Component={SessionEntity}>
            <thead>
            <tr>
                <IdColumn/>
                { user.type > 8 && <Column sort="first_name">Vorname</Column> }
                { user.type > 8 && <Column sort="last_name">Nachname</Column> }
                <Column className="text-center" sort="score">Min.</Column>
                <Column className="text-center" sort="score">Std.</Column>
                { user.type >= 128 && <Column className="text-center">Person</Column> }
                { user.type >= 128 && <Column className="text-center">Erstellungsdatum</Column> }
                <Column className="text-center" >Wochenplanung</Column>
                <Column className="text-center" >Assistenzplan</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    { user.type > 8 && <td><InlineEditLink Component={UserEntity} id={row.user.id}>{row.user.first_name}</InlineEditLink></td> }
                    { user.type > 8 && <td><InlineEditLink Component={UserEntity} id={row.user.id}>{row.user.last_name}</InlineEditLink></td> }
                    <td className="text-center">{row.score.toFixed(2) } {row.attachment_note && <>({row.attachment_note})</>}</td>
                    <td className="text-center">{ ((row.score / 60).toFixed(2)) }</td>
                    { user.type >= 128 && <td className="text-center">{((row.score / 60 / (row.user.question && row.user.question.level) * 7).toFixed(2)) + " (" + labels[row.user.question && row.user.question.level] + ")"}</td> }
                    { user.type >= 128 && <td className="text-center"><FormattedDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                        value={new Date(row.created_at).toJSON()}/></td> }
                    <SessionTableButton id={row.id} pdfLink={row.pdf_link} status={row.status} />
                    <td className="min">
                        <Link to={row.id + "/assistant-plan"} className="btn btn-outline-primary ms-1"> <FormattedMessage id="ASSISTANT_PLAN"/></Link>
                        { row.assistant_plan_pdf_link && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + row.assistant_plan_pdf_link} className="btn ms-1 btn-outline-primary" target="_blank"><i className="fas fa-file-pdf" /></a> }
                    </td>
                    <Actions
                        edit={user.type >= 128 && ("/session/" + row.id + '/edit')}
                        del={user.type === 512 && ("/session/" + row.id)}
                    >
                        <Link to={row.id + "/files"} className="btn btn-outline-primary ms-1"> <FormattedMessage id="SESSION_FILES"/></Link>
                        <Link to={row.id + "/bedafsrechner"} className="btn btn-outline-primary ms-1">
                            <i className="fas fa-file me-1" />
                            <FormattedMessage id="BEDAFSRECHNER"/>
                        </Link>
                        <SessionChatButton session={row} />
                    </Actions>
                </tr>}
            />
            <>
            <Routes>
                <Route path=":id/assistant-plan" element={<AssistantPlanEntity />} />
                <Route path=":id/files" element={<AttachmentsEntity />} />
                <Route path=":id/bedafsrechner" element={<BedafsrechnerPage/>}/>
            </Routes>
            <ExcelExportButton
                name="Antrag"
                params={{
                    sort: 'id',
                    desc: false
                }}
                header={[
                    "#",
                    "Vorname",
                    "Nachname",
                    "E-Mail",
                    "Min.",
                    "Std.",
                    "Person",
                    "Module",
                    "Erstellungsdatum"
                ]}
                map={row => [
                    row.id,
                    row.first_name,
                    row.last_name,
                    row.email,
                    row.score.toFixed(2),
                    ((row.score / 60).toFixed(2)),
                    (((row.score / 60 / row.level * 7).toFixed(2)) + " (" + labels[row.level] + ")"),
                    row.quiz.name,
                    new Date(row.created_at)
                ]}
            />
        </>
        </CRUD>
    </Breadcrumb>;
}
