import {
    Actions,
    ActionsColumn,
    Column,
    CRUD,
    IdColumn,
    RowRenderer,
    useDataTableContext
} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import UserEntity, {roles} from "./UserEntity";
import {FormattedMessage} from "react-intl";
import {UserPaymentApproveButton} from './UserPaymentApproveButton';
import React from "react";
import {useUser} from "../../Components/UserProvider";
import {useFetch} from "react-admin-base";
import {CouponSelect} from "../../Components/Selects";
import {Col} from "reactstrap";

const paymentMethods = {
    4: 'Banküberweisung',
    2: 'Paypal / Kreditkarte'
};

function CouponFilter() {
    const [ params, setParams ] = useDataTableContext();
    const [ coupon ] = useFetch(params.coupon_id && ('coupon/' + params.coupon_id));

    return <CouponSelect
        value={coupon}
        onChange={a => setParams({ coupon_id: a?.id || null })}
    />;
}

export default function Users({url, approved}) {
    const user = useUser();

    const defaultParams = {
        sort: 'id',
        desc: true
    };

    return <Breadcrumb
        data={
            [
                {
                    href: url,
                    name: <FormattedMessage id={approved === "false" ? "PENDING_USERS" : "USERS"}/>
                }
            ]
        }
    >
        <CRUD url={url} defaultParams={defaultParams} apiUrl={"/user?approved=" + (approved || '')} Component={UserEntity}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="first_name">Vorname</Column>
                <Column sort="last_name">Nachname</Column>
                <Column sort="email">E-Mail-Adresse</Column>
                <Column sort="type">Status</Column>
                <Column className="min text-center">Coupon</Column>
                <Column className="text-center" sort="payment_method">Payment</Column>
                <Column className="text-center" sort="payment_method"><FormattedMessage id="SESSION_FILES"/></Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.first_name}</td>
                    <td>{row.last_name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    <td className="min text-center">{row.coupon?.name || '-'} {row.coupon && <b>({row.coupon.reseller_price}&euro;)</b> }</td>
                    <td className="text-center">{(row.payment_method && paymentMethods[row.payment_method]) || '-'}
                    </td>
                    <td className="text-center">
                        {row.type === 8 && !row.receipt && row.invoice &&
                            <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + row.invoice.file.access_url} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                                <i className="fas fa-file-pdf"/> Proformarechnung
                            </a>}
                        {row.type === 8 && row.receipt && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + row.receipt.file.access_url} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                            <i className="fas fa-file-pdf"/> Rechnung
                        </a>}
                    </td>
                    <Actions
                        edit={url + "/" + row.id + '/edit'}
                        del={"/user/" + row.id}
                    >
                        {row.type === 8 && !row.paid_at && <UserPaymentApproveButton id={row.id}/>}
                    </Actions>
                </tr>}
            />
            <>
                <Col>
                    <CouponFilter />
                </Col>
            </>
        </CRUD>
    </Breadcrumb>;
}
