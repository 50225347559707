import {useIntl} from "react-intl";
import SessionCreate from "./SessionCreate";
import {useFetch} from "react-admin-base";
import { Navigate } from "react-router-dom";

export default function FirstSession() {
    const [ sessions ] = useFetch('/session?itemPerPage=1');
    const intl = useIntl();

    if (!sessions)
        return null;

    if (sessions.data.length > 0)
        return <Navigate to="/" />;

    const lang = intl.locale.split('-')[0];
    const id = lang === "tr" ? 2 : 1;

    return <SessionCreate
        key={id}
        id={id}
    />;
}
