import React from 'react';
import LanguageEntity from "./LanguageEntity";
import {useUser} from "../../Components/UserProvider";
import {Route, Routes} from "react-router-dom";
import {Actions, ActionsColumn, BootstrapDataTable, Column, IdColumn, Relative, RowRenderer} from "react-admin-base-bootstrap";
import {Breadcrumb} from "react-admin-base-front";
import { FormattedMessage } from 'react-intl';

export default function Languages() {
    const user = useUser();

    return <Routes>
        <Route path=":id/edit" element={<LanguageEntity />} />
        <Route path="create" element={<LanguageEntity />} />
        <Route path="*" element={
        <Breadcrumb
            data={
                [
                    {
                        href: '/language',
                        name: <FormattedMessage id="LANGUAGE_FEATURES"/>
                    }
                ]
            }
        >
            <BootstrapDataTable url="/language" add="/language/create">
                <thead>
                    <tr>
                        <IdColumn />
                        <Column className="min text-center">Ikon</Column>
                        <Column sort="name">Name</Column>
                        <Column sort="language_name">Sprache</Column>
                        <Column sort="language_code" className="text-center">Sprachkode</Column>
                        <ActionsColumn />
                    </tr>
                </thead>
                <RowRenderer
                    render={row => <tr key={row.id}>
                        <td className="min text-center">{row.id}</td>
                        <td className="min text-center">
                            { row.icon && <Relative>
                                <img src={row.icon.access_url} style={{ width: '24px', height: '24px' }} alt={row.name} />
                            </Relative> }
                        </td>
                        <td>{row.name}</td>
                        <td>{row.language_name}</td>
                        <td className="text-center">{row.language_code}</td>
                        <Actions
                            edit={(user.type === 512 || (row.company && row.company.id === user.company.id)) && ("/language/" + row.id + "/edit")}
                        />
                    </tr>
                } />
            </BootstrapDataTable>
        </Breadcrumb>
        } />
    </Routes>;
}
