import {FormattedMessage, useIntl } from "react-intl";
import {Button, ButtonGroup, Col, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {LevelSelect, SpauseStatusSelect} from "./Selects";
import {Field} from "./Fields";
import { useValidate } from "react-admin-base";
import React from "react";

function ValidatorCore(name, value, type, children) {
    const error = useValidate(name || '', value, type);

    return <>
        {(error
            && React.cloneElement(children, { invalid: !!error, className: (children.props.className || '') + (!!error ? ' is-invalid' : '') })) || children}
        {error && <FormFeedback className="d-block">{error}</FormFeedback>}
    </>;
}

export function Validator({ name, type, children }) {
    return ValidatorCore(name, children.props.value === 0 || children.props.value === false || children.props.checked === false ? "false" : (children.props.value || children.props.checked), type, children);
}

export default function UserQuestionInput({ value, onChange }) {
    const intl = useIntl();
    return <Row>
        <Col>
            <Question title="LEVEL">
                <Validator name="level" type="required">
                    <LevelSelect
                        value={value.level || ''}
                        onChange={a => onChange({ ...(value || {}), level: a })}
                    />
                </Validator>
            </Question>
            
            <Question title="SPAUSE_STATUS">
                <Validator name="spause" type="required">
                    <SpauseStatusSelect
                        value={value.spause_status}
                        onChange={a => onChange({ ...(value || {}), spause_status: a === "false" ? false : a === "true" ? true : '' })}
                    />
                </Validator>
            </Question>

            <Question title="CHILDREN_STATUS">
                <Validator name="children_count" type="required">
                    <ChildrenCountSelect
                        value={value.children_count}
                        onChange={a => onChange({ ...(value || {}), children_count: a })}
                    />
                </Validator>
            </Question>

            <Question title="HOME_ARRANGEMENT">
                <Validator name="home_arrangement" type="required">
                    <HomeArrangementSelect
                        value={value.home_arrangement || ''}
                        onChange={a => onChange({ ...(value || {}), home_arrangement: a })}
                    />
                </Validator>
            </Question>
            <Question title="HOW_MANY_ROOM">
                <Validator name="room_count" type="required|numeric">
                    <Input
                        type="number"
                        value={value.room_count || ''}
                        onChange={a => onChange({ ...(value || {}), room_count: a.currentTarget.value })}
                    />
                </Validator>
            </Question>

        </Col>
        <Col>
            <Question title="BATHROOM_STATUS">
                <Validator name="bathroom_status" type="required">
                    <BathroomStatusSelect
                        value={value.bathroom_status}
                        onChange={a => onChange({ ...(value || {}), bathroom_status: a })}
                    />
                </Validator>
            </Question>

            <Question title="SHOWER_LEVEL">
                <Validator name="shower_level" type="required">
                    <BathroomLevelSelect
                        value={value.shower_level}
                        onChange={a => onChange({ ...(value || {}), shower_level: a })}
                    />
                </Validator>
            </Question>

            <Question title="HAS_MEDICAL_REPORTS">
                <Validator name="has_medical_records" type="required">
                    <YesNoSelect
                        value={value.has_all_medical_reports}
                        onChange={a => onChange({ ...(value || {}), has_all_medical_reports: a })}
                    />
                </Validator>
            </Question>

            <Question title="HOW_TALL_ARE_YOU">
                <Row>
                    <Col>
                        <Field title={ intl.formatMessage(({ id: 'WEIGHT' })) } type="required">
                            <Input
                                type="number"
                                value={value.weigth || ''}
                                onChange={a => onChange({ ...(value || {}), weigth: a.currentTarget.value })}
                            />
                        </Field>
                    </Col>
                    <Col>
                        <Field title={ intl.formatMessage(({ id: 'HEIGHT' })) } type="required">
                            <Input
                                type="number"
                                value={value.height || ''}
                                onChange={a => onChange({ ...(value || {}), height: a.currentTarget.value })}
                            />
                        </Field>
                    </Col>
                </Row>
            </Question>

            <Question title="WHO_HELPED_YOU">
                <Validator name="helper_name" type="required">
                    <Input
                        type="text"
                        value={value.helper_name || ''}
                        onChange={a => onChange({ ...(value || {}), helper_name: a.currentTarget.value })}
                    />
                </Validator>
            </Question>

            <Question title="HAS_DISABLED_ID">
                <Validator name="has_disabled_id" type="required">
                    <YesNoSelect
                        value={value.has_disabled_id}
                        onChange={a => onChange({ ...(value || {}), has_disabled_id: a })}
                    />
                </Validator>
                { value.has_disabled_id && <Field className="mt-3" title="Merkzeichen" >
                    <MerkzeichenSelect
                        value={value.disabled_id_merkzeichen}
                        onChange={a => onChange({ ...(value || {}), disabled_id_merkzeichen: a })}
                    />
                </Field> }
                { value.has_disabled_id &&
                    <Question title="GRAD">
                        <Validator name="grad" type="required|numeric">
                            <Input
                                type="number"
                                value={value.grad || ''}
                                onChange={a => onChange({ ...(value || {}), grad: a.currentTarget.value })}
                            />
                        </Validator>
                    </Question> }
            </Question>
        </Col>
    </Row>;
}

function ChildrenCountSelect({ value, onChange }) {
    const intl = useIntl();

    return <>
        <YesNoSelect value={ value !== 0 } onChange={a => onChange(a ? 1 : 0)} />
        { value !== 0 && <Field title={ intl.formatMessage(({ id: 'COUNT' })) } className="mt-3">
            <Input
                type="number"
                value={value || ''}
                onChange={a => onChange(a.currentTarget.value)}
            />
        </Field> }
    </>;
}

function MerkzeichenSelect({ value, onChange }) {
    const levels = ["aG", "B", "BI", "G", "GI", "H", "RF", "TBI"];

    return <ButtonGroup className="d-flex">
        {
            levels.map((a, i) => <Button
                type="button"
                color="primary"
                outline={(value & (1 << i)) !== (1 << i)}
                onClick={_ => onChange(((value || 0) ^ (1 << i)))}
            >
                {a}
            </Button>)
        }
    </ButtonGroup>;
}

function HomeArrangementSelect({ value, onChange, className }) {
    const intl = useIntl();

    return <Input type="select" className={className} value={(value || '').toString()} onChange={a => onChange(+a.currentTarget.value)}>
        <option>{ intl.formatMessage(({ id: 'PLEASE_SELECT' })) }</option>
        <option value="1">{ intl.formatMessage(({ id: 'AT_GROUND_LEVEL' })) }</option>
        <option value="2">{ intl.formatMessage(({ id: 'BY_STAIRS' })) }</option>
    </Input>;
}

function BathroomStatusSelect({ value, onChange, className }) {
    const intl = useIntl();

    return <Input type="select" className={className} value={(value || '').toString()} onChange={a => onChange(+a.currentTarget.value)}>
        <option>{ intl.formatMessage(({ id: 'PLEASE_SELECT' })) }</option>
        <option value="1">{ intl.formatMessage(({ id: 'SHOWER' })) }</option>
        <option value="2">{ intl.formatMessage(({ id: 'BATHTUB' })) }</option>
    </Input>;
}

function BathroomLevelSelect({ value, onChange, className }) {
    const intl = useIntl();

    return <Input type="select" className={className} value={(value || '').toString()} onChange={a => onChange(+a.currentTarget.value)}>
        <option>{ intl.formatMessage(({ id: 'PLEASE_SELECT' })) }</option>
        <option value="1">{ intl.formatMessage(({ id: 'GROUND_LEVEL' })) }</option>
        <option value="2">{ intl.formatMessage(({ id: 'HIGH_ENTRY' })) }</option>
    </Input>;
}

function YesNoSelect({ value, onChange }) {
    const intl = useIntl();

    return <Row>
        <Col>
            <Button type="button" color="primary" block outline={value !== true} onClick={_ => onChange(true)}>
                { intl.formatMessage(({ id: 'YES' })) }
            </Button>
        </Col>
        <Col>
            <Button type="button" color="danger" block outline={value !== false} onClick={_ => onChange(false)}>
                { intl.formatMessage(({ id: 'NO' })) }
            </Button>
        </Col>
    </Row>;
}

function Question({ title, children }) {
    return <FormGroup>
        <Label><FormattedMessage id={ title } /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
        {children}
    </FormGroup>;
}
