import React, {useCallback, useState} from 'react'
import PayPal from '../PaymentMethods/PayPal'
import {CheckBox} from 'react-admin-base-bootstrap';
import Zahlung from './Zahlung';
import {FormattedMessage} from "react-intl";

const paymentMethods = {
    2: {
        name: 'Paypal / Kreditkarte',
        component: PayPal
    },
    4: {
        name: 'Banküberweisung',
        component: Zahlung
    }
};

export default function PayNow({onSubmit}) {
    const [paymentMethodId, setPaymentMethodId] = useState(2);

    const Component = paymentMethods[paymentMethodId].component;

    const _onSubmit = useCallback(function (data) {
        return onSubmit({
            payment_method: paymentMethodId,
            ...(data || {})
        });
    }, [paymentMethodId, onSubmit]);

    return <div>
        <h5><FormattedMessage id="SESSION_PAYMENT_METHOD"/></h5>

        <div className="my-4">
            {
                Object.entries(paymentMethods)
                    .map(([id, obj]) => {
                        return <CheckBox
                            type="radio"
                            id={"pay_" + id}
                            checked={+paymentMethodId === +id}
                            onChange={() => setPaymentMethodId(+id)}
                            label={obj.name}
                        />;
                    })
            }
        </div>

        <Component
            onSubmit={_onSubmit}
        />
    </div>;
}
