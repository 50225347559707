import {
    Actions,
    ActionsColumn,
    BootstrapDataTable,
    Column,
    CRUD,
    CRUDActions,
    IdColumn,
    RowRenderer
} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {FormattedMessage} from "react-intl";
import { Link } from 'react-router-dom';
import React from "react";

const defaultParams = {
    sort: 'id',
    desc: true
};

export default function InvoiceStats() {
    return <Breadcrumb
        data={
            [
                {
                    href: "/invoice-stat",
                    name: <FormattedMessage id={"INVOICE_ARCHIVE"}/>
                }
            ]
        }
    >
        <BootstrapDataTable url="/invoice-stat" defaultParams={defaultParams}>
            <thead>
                <tr>
                    <Column sort="year">Year</Column>
                    <Column sort="month">Month</Column>
                    <Column sort="count">Count</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.year}</td>
                    <td className="min text-center">{row.month}</td>
                    <td className="">{row.count}</td>
                    <Actions>
                        <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + row.download_link} className="btn btn-outline-primary ms-1">
                            <i className="fas fa-file-invoice me-1" />
                            <FormattedMessage id={"DOWNLOAD"}/>
                        </a>
                    </Actions>
            </tr>}
            />
        </BootstrapDataTable>
    </Breadcrumb>;
}
