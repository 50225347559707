import React from 'react';
import {Card, CardBody, Col, Input, Label, Row, ModalBody, FormGroup} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Validator, ModalEntityEditor, PasswordInput, CheckBox} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";
import {Field} from "../../Components/Fields";
import {FormattedMessage} from "react-intl";
import AddressInput from "../../Components/AddressInput";

export default function ResellerEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/reseller', id, { active: true });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Reseller-Administration" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Field translate="NAME" type="required">
                <Input
                    type="text"
                    value={data.name || ''}
                    onChange={a => setData({name: a.currentTarget.value})}
                />
            </Field>
            <Field translate="MANAGER_NAME" type="required">
                <Input
                    type="text"
                    value={data.manager_name || ''}
                    onChange={a => setData({manager_name: a.currentTarget.value})}
                />
            </Field>
            <Field translate="ADDRESS">
                <AddressInput
                    prefix="reseller_"
                    value={data.address}
                    onChange={address => setData({ address })}
                />
            </Field>
            <Field translate="EMAIL" type="required|email">
                <Input
                    type="text"
                    value={data.email || ''}
                    onChange={a => setData({email: a.currentTarget.value})}
                />
            </Field>
            <Field translate="PHONE" type="required">
                <Input
                    type="text"
                    value={data.phone || ''}
                    onChange={a => setData({phone: a.currentTarget.value})}
                />
            </Field>
            <Field translate="IBAN" type="required">
                <Input
                    type="text"
                    value={data.iban || ''}
                    onChange={a => setData({iban: a.currentTarget.value})}
                />
            </Field>
            <Field translate="HRB" type="required">
                <Input
                    type="text"
                    value={data.hrb_no || ''}
                    onChange={a => setData({hrb_no: a.currentTarget.value})}
                />
            </Field>
        </ModalBody>
    </ModalEntityEditor>;
}
