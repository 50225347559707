import React from 'react';
import {Breadcrumb} from 'react-admin-base-front';
import {Link} from "react-router-dom";
import {Col, Row, Card, CardBody} from 'reactstrap';
import {useUser} from "../../Components/UserProvider";
import {FormattedMessage} from "react-intl";

export default function Dashboard() {
    const user = useUser();
    return <Breadcrumb
        data={
            [
                {
                    href: '/dashboard',
                    name:  <FormattedMessage id="DASHBOARD"/>
                }
            ]
        }
    >
        <Card>
            <CardBody>
                <Row>
                    <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/session"} color="info">
                                <i className="fas fa-chart-line"></i>{" "}
                                <FormattedMessage id="SESSIONS"/>
                            </Link>
                        </Card>
                    </Col>
                    {user.type === 512 && <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/language"} color="info">
                                <i className="fas fa-language"></i>{" "}
                                <FormattedMessage id="LANGUAGE_FEATURES"/>
                            </Link>
                        </Card>
                    </Col>}
                    {user.type === 512 && <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/quiz"} color="info">
                                <i className="fas fa-question-circle"></i>{" "}
                                <FormattedMessage id="MODULES"/>
                            </Link>
                        </Card>
                    </Col>}
                    {user.type === 512 && <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/option-group"} color="info">
                                <i className="fas fa-check-circle"></i>{" "}
                                <FormattedMessage id="OPTIONS"/>
                            </Link>
                        </Card>
                    </Col>}
                    {user.type >= 128 && <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/user"} color="info">
                                <i className="fa fa-users"></i>{" "}
                                <FormattedMessage id="USERS"/>
                            </Link>
                        </Card>
                    </Col>}
                    {user.type >= 256 && <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/pending-user"} color="info">
                                <i className="fa fa-users"></i>{" "}
                                <FormattedMessage id="PENDING_USERS"/>
                            </Link>
                        </Card>
                    </Col>}
                    <Col md="6">
                        <Card>
                            <Link className="btn btn-info m-2 text-white" to={"/profile"} color="info">
                                <i className="fa fa-user"></i>{" "}
                                <FormattedMessage id="MY_PROFILE"/>
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    </Breadcrumb>;
}
