import { Routes, Route, Navigate } from 'react-router-dom';

import Dashboard from "./pages/Dash/Dashboard";

import Profile from "./pages/Profile/Edit";

import Users from "./pages/User/Users";
import Quizs from "./pages/Quiz/Quizs";
import Options from "./pages/Option/Options";
import Languages from "./pages/Language/Languages";
import Sessions from "./pages/Session/Sessions";
import { useUser } from './Components/UserProvider';
import Coupons from "./pages/Coupon/Coupons";
import Resellers from "./pages/Resellers/Resellers";
import InvoiceStats from './pages/InvoiceStat/InvoiceStats';

export default function Router() {
    const user = useUser();
    
    return <Routes>
        <Route path="/dashboard" element={<Dashboard />} />

        { user.type >= 128 && <Route path="/user/*" element={<Users url="/user" approved="true" />}/> }
        { user.type >= 256 && <Route path="/pending-user/*" element={<Users url="/pending-user" approved="false" />}/> }
        { user.type >= 256 && <Route path="/invoice-stat/*" element={<InvoiceStats />}/> }

        <Route path="/profile" element={<Profile />}/>
        
        { user.type === 512 && <Route path="/coupon/*" element={<Coupons />} /> }
        { user.type === 512 && <Route path="/quiz/*" element={<Quizs />} /> }
        { user.type === 512 && <Route path="/option-group/*" element={<Options />} /> }
        { user.type === 512 && <Route path="/language/*" element={<Languages />} /> }
        { user.type >= 256 && <Route path="/reseller/*" element={<Resellers />} /> }

        <Route path="/session/*" element={<Sessions />} />

        <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>;
}
