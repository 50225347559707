import React from 'react';
import {Card, CardBody, Col, Input, Label, Row, ModalBody, FormGroup} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Validator, ModalEntityEditor, PasswordInput} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";
import AddressInput from '../../Components/AddressInput';
import {FormattedMessage} from "react-intl";
import UserQuestionInput from "../../Components/UserQuestionInput";

export const roles = {
    '512': 'Admin',
    '256': 'Accountant',
    '128': 'Lawyer',
    '8': 'User'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, { type: 512 });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Benutzer-Administration" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Row>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="VORNAME"/></Label>
                        <Validator name="first_name" type="required">
                            <Input
                                type="text"
                                value={data.first_name || ''}
                                onChange={a => setData({first_name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="NACHNAME"/></Label>
                        <Validator name="last_name" type="required">
                            <Input
                                type="text"
                                value={data.last_name || ''}
                                onChange={a => setData({last_name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label><FormattedMessage id="EMAIL"/></Label>
                <Validator name="email" type="required">
                    <Input type="text" value={data.email || ''}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <PasswordInput
                value={data.password || ''}
                onChange={a => setData({password: a})}
            />

            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="PROFILE_BIRTHDAY"/></Label>
                <Validator name="email" type="required">
                    <Input type="date" value={data.birthday}
                           onChange={a => setData({birthday: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="PHONE"/></Label>
                <Input type="text" value={data.phone || ''}
                       onChange={a => setData({phone: a.currentTarget.value})}/>
            </FormGroup>

            <FormGroup>
                <Label><FormattedMessage id="PROFILE_ADRESS"/></Label>
                <AddressInput prefix="user_" value={data.address} onChange={address => setData({ address })} />
            </FormGroup>

            { data.type === 8 && <UserQuestionInput
                value={data.question || {}}
                onChange={question => setData({ question })}
            /> }

            { user.type === 512 && <FormGroup>
                <Label>Status</Label>
                <Input
                    type="select"
                    value={data.type}
                    onChange={a => setData({type: +a.currentTarget.value, supplier:null})}
                >
                    { Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id} key={id}>{role}</option>)}
                </Input>
            </FormGroup> }
        </ModalBody>
    </ModalEntityEditor>;
}
