import React, {useCallback, useEffect, useState} from "react";
import Themes from "react-admin-base-front/lib/esm/Themes";
import {FormattedMessage, useIntl} from "react-intl";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Form, Container
} from "reactstrap";
import {Validator, CheckBox, useLanguage} from 'react-admin-base-bootstrap';
import AddressInput from "../Components/AddressInput";
import {useAuth, useFreeFetch, useQueryParams, useValidate} from "react-admin-base";
import {Step, Steps} from "../Components/Steps";
import PayNow from "../Components/PaymentMethods/PayNow";
import { useOnSuccess } from "../Components/useOnSuccess";
import UserQuestionInput from "../Components/UserQuestionInput";
import {Field} from "../Components/Fields";

function useAutoLanguage() {
    const { lang } = useQueryParams();
    const [, languages, setLanguage ] = useLanguage();

    useEffect(function() {
        if (lang) {
            const _lang = Object.keys(languages).find(b => b.split('-')[0] === lang);
            if (_lang) {
                setLanguage(_lang);
                const url = new URL(window.location);
                url.searchParams.delete('lang');
                window.history.pushState(null, '', url.toString());
            }
        }
    }, [lang, languages, setLanguage]);
}

function useCouponCode() {
    const [ code, setCode ] = useState('');
    const [ coupon, setCoupon ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState();
    const [ api ] = useAuth();

    const apply = useCallback(async function() {
        setLoading(true);
        setError(null);
        try {
            const data = await api.free
                .get('/coupon/by-code/' + encodeURIComponent(code));
            setCoupon(data.data);
            setError(null);
        } catch(e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [code, setCoupon, setError, setLoading]);

    return [ code, setCode, apply, coupon, loading, error, setError];
}

export default function Register() {
    const [ data, setData ] = useState({});
    const [ questions, setQuestions ] = useState({});
    const [ code, setCode, apply, coupon, loadingCoupon, errorCoupon, setErrorCoupon ] = useCouponCode();

    const intl = useIntl();
    const [ api ] = useAuth();
    const [ isPending, setIsPending ] = useState(false);

    useAutoLanguage();

    const [ onSubmit, loading, error ] = useOnSuccess(
        useCallback(async function(paymentInfo) {
            const response = await api.free.post("/register", {
                ...data,
                coupon,
                question: questions,
                paymentInfo
            });

            if (!response.data.pending) {
                await api.log_in_by_code(response.data.code, "register");
            } else {
                setIsPending(true);
                //await Swal.fire('Success', 'We will review your payment and activate your account.', 'success');
            }
        }, [ data, coupon, questions, api ])
    );

    return <Themes>
        <Container>
            { isPending && <Alert color="warning">
                <h1><FormattedMessage id="PENDING_PAYMENT_CONFIRMATION_TITLE" /></h1>
                <p><FormattedMessage id="PENDING_PAYMENT_CONFIRMATION" /></p>
            </Alert> }

            { /* <Row>
            <Col md="2">
            <LanguageSwitcher />
            </Col>
            </Row> */ }

            { !isPending && <Steps submit={onSubmit}>
                <Step translate="ACCOUNT_DETAILS">
                    <ContactModule
                        value={data}
                        setValue={setData}
                    />
                </Step>
                <Step translate="QUESTIONS">
                    <UserQuestionInput
                        value={questions}
                        onChange={setQuestions}
                    />
                </Step>
                <Step translate="PAYMENT">
                    <Row>
                        <Col>
                            <Field translate="COUPON_CODE">
                                <Input
                                    type="text"
                                    value={code || ''}
                                    onChange={a => setCode(a.currentTarget.value)}
                                    disabled={coupon || loading || loadingCoupon}
                                />
                            </Field>
                        </Col>
                        <Col className="col-auto align-self-end mb-3">
                            <Button type="button" outline color="primary" onClick={apply} disabled={coupon || !code || loading || loadingCoupon}>
                                <FormattedMessage id="APPLY_COUPON_CODE" />
                            </Button>
                        </Col>
                    </Row>
                    { errorCoupon && <Alert color="danger" toggle={() => setErrorCoupon(null)}><FormattedMessage id={errorCoupon?.message || 'COUPON_ERROR'} /></Alert> }
                    { coupon && <Alert>
                        <i className="fas fa-check me-2" />
                        <FormattedMessage id="COUPON_APPILED" values={{ price: coupon.price }} />
                    </Alert> }
                    <PayNow
                        onSubmit={onSubmit}
                    />
                </Step>
                <Step translate="BEDARFSRECHNER_REGISTER">
                </Step>
            </Steps> }
        </Container>
    </Themes>;
}

function CustomCard({ title, children }) {
    return <Card className="mb-3">
        <CardHeader>
            <CardTitle><FormattedMessage id={title} /></CardTitle>
        </CardHeader>
        <CardBody>
            { children }
        </CardBody>
    </Card>;
}

function EmailCheck({ email }) {
    const [ data, loading, error ] = useFreeFetch('/user-check?email=' + encodeURIComponent(email));

    useValidate("email_check", !(loading || error || !data), 'accepted');

    if (loading) {
        return <Alert color="warning">
            <i className="fas fa-spin fa-spinner" />{' '}<FormattedMessage id="CHECKING_EMAIL" />
        </Alert>;
    }

    if (error || !data) {
        return <Alert color="danger">
            <i className="fas fa-times-circle" />{' '}<FormattedMessage id="EMAIL_EXISTS" />
        </Alert>;
    }

    return null;
}

function ContactModule({ value, setValue }) {
    const [ email2, setEmail2 ] = useState((value && value.email) || '');

    return <div>
        <div className="my-3">
            <Row>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="VORNAME" /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                        <Validator name="vorname" type="required">
                            <Input
                                type="text"
                                value={value.first_name || ''}
                                onChange={a => setValue({ ...(value || {}), first_name: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="NACHNAME" /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                        <Validator name="nachname" type="required">
                            <Input
                                type="text"
                                value={value.last_name || ''}
                                onChange={a => setValue({ ...(value || {}), last_name: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="EMAIL" /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                        <Validator name="email" type="required|email">
                            <Input
                                type="text"
                                value={value.email || ''}
                                onChange={a => setValue({ ...(value || {}), email: a.currentTarget.value })}
                            />
                        </Validator>
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="EMAIL2" /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                        <Validator name="email2" type={['required', 'email', { in: value.email }]}>
                            <Input
                                type="text"
                                value={email2 || ''}
                                onChange={a => setEmail2(a.currentTarget.value)}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            { value.email && value.email === email2 && <EmailCheck email={value.email} /> }
            <FormGroup>
                <Label><FormattedMessage id="PHONE" /><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                <Validator name="phone" type="required">
                    <Input
                        type="text"
                        value={value.phone || ''}
                        onChange={a => setValue({ ...(value || {}), phone: a.currentTarget.value })}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="PROFILE_BIRTHDAY"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                <Validator name="email" type="required">
                    <Input type="date"
                        value={value.birthday}
                        onChange={a => setValue({ ...(value || {}), birthday: a.currentTarget.value })}
                        max={new Date().toISOString().split('T')[0]}
                    />
                </Validator>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="PROFILE_ADRESS"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
                <AddressInput
                    prefix="user_"
                    value={value.address}
                    onChange={a => setValue({ ...(value || {}), address: a })}
                />
            </FormGroup>

            <Validator name="tos" type="accepted">
                <CheckBox
                    id="tos"
                    type="checkbox"
                    label={<FormattedMessage id="TOS" values={{
                        a: text => <a href={process.env.REACT_APP_ENDPOINT + "/agb.pdf"} target="_blank">{text}</a>,
                        b: text => <a href={process.env.REACT_APP_ENDPOINT + "/datenschutzerklarung.pdf"} target="_blank">{text}</a>
                    }} />}
                    checked={!!value.check}
                    onChange={a => setValue({ ...(value || {}), check: !value.check })}
                />
            </Validator>

            <div className="mt-3">
                <Validator name="tos2" type="accepted">
                    <CheckBox
                        id="tos2"
                        type="checkbox"
                        label={<FormattedMessage id="TOS2" values={{
                            a: text => <a href={process.env.REACT_APP_ENDPOINT + "/agb.pdf"} target="_blank">{text}</a>,
                            b: text => <a href={process.env.REACT_APP_ENDPOINT + "/datenschutzerklarung.pdf"} target="_blank">{text}</a>
                        }} />}
                        checked={!!value.check2}
                        onChange={a => setValue({ ...(value || {}), check2: !value.check2 })}
                    />
                </Validator>
            </div>
        </div>
    </div>;
}
