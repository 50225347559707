import React from 'react';
import {Col, Input, Label, Row, ModalBody, FormGroup, Table} from "reactstrap";
import {useEntity} from "react-admin-base";
import {MultiFilePicker, Validator, ModalEntityEditor, SingleFilePicker} from 'react-admin-base-bootstrap';
import {useUser} from "../../Components/UserProvider";
import {CKEditorWithUpload} from "react-admin-base-ckeditor";

export const labels = {
    40: 'Pflegegrad 2,3',
    30: 'Pflegegrad 4',
    25: 'Pflegegrad 5'
};

var groupBy = function (arr, criteria) {
    return arr.reduce(function (obj, item) {

        // Check if the criteria is a function to run on the item or a property of it
        var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;

    }, {});
};

export function AssistantTable({value}) {
    return <Table hover striped>
        <thead>
        <tr>
            <th className="min min2">#</th>
            <th>Vorname</th>
            <th>Nachname</th>
            <th>Anschrift</th>
            <th>Vollzeit / Teilzeit / Minijob</th>
        </tr>
        </thead>
        <tbody>
        {(value || []).map((a, i) => <tr key={a.id}>
            <td className="min min2">{i + 1}.</td>
            <td>{a.first_name}</td>
            <td>{a.last_name}</td>
            <td>{a.address}</td>
            <td>{a.position}</td>
        </tr>)}
        </tbody>
    </Table>;
}

export default function SessionEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/session', id, {lang_code: 'de', pages: [{}]});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title={"Sitzungsverwaltung #" + id} url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>Vorname</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.user?.first_name || ''}
                                disabled
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label>Nachname</Label>
                        <Validator name="last_name" type="required">
                            <Input
                                type="text"
                                value={data.user?.last_name || ''}
                                disabled
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>E-Mail</Label>
                        <Validator name="email" type="required">
                            <Input
                                type="text"
                                value={data.user?.email || ''}
                                disabled
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label>Telefon</Label>
                        <Validator name="lang_code" type="required">
                            <Input
                                type="text"
                                value={data.user?.phone || ''}
                                disabled
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            {  <Row>
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Minuten</Label>
                                <Validator name="score" type="required">
                                    <Input
                                        type="text"
                                        value={data.score && data.score.toFixed(2)}
                                        disabled
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Stunde</Label>
                                <Validator name="score" type="required">
                                    <Input
                                        type="text"
                                        value={((data.score / 60).toFixed(2))}
                                        disabled
                                    />
                                </Validator>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                { (data.user?.question?.level && <Col>
                    <FormGroup>
                        <Label>Person</Label>
                        <Input
                            type="text"
                            value={((data.score / 60 / data.user.question.level * 7).toFixed(2)) + " (" + labels[data.user.question.level] + ")"}
                            disabled
                        />
                    </FormGroup>
                </Col>) || null }
                <Col>
                    <FormGroup>
                        <Label>Hinweis</Label>
                        <Input
                            type="text"
                            value={data.attachment_note}
                            onChange={a => setData({attachment_note: a.currentTarget.value})}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Module</Label>
                        <Validator name="lang_code" type="required">
                            <Input
                                type="text"
                                value={(data.quiz && data.quiz.name) || ''}
                                onChange={a => null}
                                disabled
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row> }
            <Row>
                { <Col>
                    <FormGroup>
                        <Label>Antrag Datei</Label>
                        <SingleFilePicker
                            value={data.attachment}
                            onChange={a => a}
                            disabled
                        />
                    </FormGroup>
                </Col>}
            </Row>
            <CKEditorWithUpload
                value={data.note || ''}
                onChange={a => setData({note: a})}
            />

            <AssistantTable value={data && data.assistants}/>
            { data && data.answers && <AnswersTable answers={data.answers} /> }
        </ModalBody>
    </ModalEntityEditor>;
}

export function AnswersTable({ answers }) {
    return <Table hover>
        <thead>
        <tr>
            <th className="min text-center">Module</th>
            <th>Fragen</th>
            <th>Antworten</th>
            { <th className="text-center min">Minuten</th>}
        </tr>
        </thead>
        <tbody>
        {
            (Object.entries(groupBy(answers, a => a.question.page.order + (a.question.page.title ? ("|||" + a.question.page.title) : '')))
                .sort((a, b) => +a[0].split("|||")[0] - +b[0].split("|||")[0])
                .map(([index, questions]) => <>
                        <tr className="table-secondary">
                            <td colSpan={4}><b>Module {+index.split("|||")[0] + 1}</b>{index.split("|||")[1] ? <> - {index.split("|||")[1]}</> : null}</td>
                        </tr>
                        {questions.map(a =>
                            <tr key={a.question.id}>
                                <td className="text-center">{a.question.page.order + 1}. {a.question.order + 1}.</td>
                                <td>{a.question.question}</td>
                                <td>{a.option.name} {(a.number && <>({a.number})</>) || false}</td>
                                { <td className="text-center min">{a.score && a.score.toFixed(2)}</td>}
                            </tr>
                        )}
                    </>
                ))
        }
        </tbody>
    </Table>;
}
