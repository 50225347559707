import { useCallback, useState } from "react";
import { useAuth } from "react-admin-base";

export function useForm(submit, deps) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ data, setData ] = useState(null);
	const [ api ] = useAuth();
	
	const submitCb = useCallback(submit, deps);
	
	const submiter = useCallback(async function(e) {
		e.preventDefault();
		
		setError(null);
		setLoading(true);
		try {
			const ret = await submitCb(api);
			setData(ret);
		} catch(e) {
			console.error(e);
			setError(e);
		} finally {
			setLoading(false);
		}
	}, [ submitCb, setLoading, setError, api ]);
	
	return [ submiter, data, error, loading ];
}
