import { useCallback, useLayoutEffect, useRef, useState } from "react";
import {useAuth, useFetch, ValidatorProvider} from "react-admin-base";
import { useParams } from "react-router-dom";
import { Form, Modal, ModalHeader } from "reactstrap";
import { useUser } from "../../Components/UserProvider";
import useChat, { ChatWrapper } from "./Socket";
import FormattedDateTime from "../../Components/FormattedDateTime";
import { FormattedMessage, useIntl } from "react-intl";

function ChatBubble({ message }) {
	const user = useUser();
	const isAdmin = user.type >= 128;
	
	return <li className={message.is_admin != isAdmin ? 'left' : 'right'}>
		<div className="conversation-list">
			<div className="ctext-wrap">
				<div className="conversation-name"><FormattedMessage id={ message.is_admin ? 'ADMIN' : 'USER' } /></div>
				<div className="ctext-wrap-content">
					<p className="mb-0">{message.body}</p>
				</div>
				<p className="chat-time mb-0">
					<i className="mdi mdi-clock-outline align-middle me-1"/>{" "}
					<FormattedDateTime value={message.created_at} />
				</p>
			</div>
		</div>
	</li>;
}

function SendChat({ sessionId }) {
	const [api] = useAuth();
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [attachments, setAttachments] = useState([]);
	
	const send = useCallback(async function (e) {
		e.preventDefault();
	
		if (!message)
			return ;
	
		setLoading(true);
		try {
			await api.tokenized.put('/session/' + sessionId + '/message', { body: message, attachments });
			setMessage('');
			setAttachments([]);
		} finally {
			setLoading(false);
		}
	}, [api, sessionId, message, setMessage, setLoading, attachments, setAttachments]);

	const intl = useIntl();
	
	return <ValidatorProvider>
		<Form onSubmit={send}>
			<div className="row">
				<div className="col">
					<div className="position-relative">
						<input
							type="text"
							className="form-control chat-input"
							placeholder={intl.formatMessage({ id: 'ENTER_MESSAGE' })}
							value={message}
							onChange={a => setMessage(a.currentTarget.value || '')}
						/>
					</div>
				</div>
				<div className="col-auto">
					<button
						type="submit"
						className="btn btn-primary chat-send w-md waves-effect waves-light"
					>
						<i className="fas fa-envelope"/>
						<span className="d-none d-sm-inline-block ms-2"><FormattedMessage id="SEND" /></span>
					</button>
				</div>
			</div>
		</Form>
	</ValidatorProvider>;
}

function Scroller({ children }) {
	const ref = useRef();
	
	useLayoutEffect(function() {
		const observer = new MutationObserver(function() {
			ref.current.scrollTop = ref.current.scrollHeight;
		});
		
		observer.observe(ref.current, { childList: true, subtree: true });

		return function() {
			observer.disconnect();	
		};
	}, [])
	
	return <div ref={ref} className="chat-conversation p-3">
		{ children }
	</div>
}

function ChatBoxInner({ id, showTitle }) {
	const messages = useChat(id);
	
	return <div className="w-100 user-chat mt-4 mt-sm-0">
		{ showTitle && <div className="p-3 px-lg-4 user-chat-border">
			<div className="row">
				<div className="col-md-4 col-6">
					<h5 className="font-size-15 mb-1 text-truncate">Session #{id}</h5>
				</div>
			</div>
		</div> }
		<div className="px-lg-2">
			<Scroller>
				<ul className="list-unstyled mb-0 pe-3">
					{ [...(messages || [])].reverse().map(a => <ChatBubble key={a.id} message={a} />) }
				</ul>
			</Scroller>
		</div>
		<div className="px-lg-3">
			<div className="p-3 chat-input-section">
				<SendChat sessionId={id} />
			</div>
		</div>
	</div>;
}

export function ChatBoxModal({ id, onClose }) {
	const [session, , , refresh] = useFetch('/session/' + id);

	return <Modal size="lg" isOpen toggle={onClose}>
		<ModalHeader toggle={onClose}>
			<FormattedMessage id="MESSAGES" /> #{id}
		</ModalHeader>
		<ChatWrapper>
			<ChatBoxInner id={id} showTitle={false} />
		</ChatWrapper>
	</Modal>
}

export default function ChatBox() {
	const { id } = useParams();
	return <ChatBoxInner id={id} showTitle />
}