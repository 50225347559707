import React from 'react';
import {Sidebar, Menu, MenuGroup} from 'react-admin-base-front';
import {useUser} from './Components/UserProvider';
import {FormattedMessage} from "react-intl";

export default function MenuSidebar() {
    const user = useUser();

    return <Sidebar>
        <MenuGroup title={<FormattedMessage id="PAGES"/>}>
            <Menu title={<FormattedMessage id="DASHBOARD"/>} to="/dashboard/index" icon="fas fa-tachometer-alt"/>
            <Menu title={<FormattedMessage id="SESSIONS"/>} to="/session" icon="fas fa-chart-line"/>

            {user.type >= 128 && <Menu title={<FormattedMessage id="USERS"/>} to="/user" icon="fas fa-users"/>}

            {user.type >= 256 && <Menu title={<FormattedMessage id="PENDING_USERS"/>} to="/pending-user" icon="fas fa-users"/>}
            {user.type >= 256 && <Menu title={<FormattedMessage id="RESELLLERS"/>} to="/reseller" icon="fas fa-network-wired"/>}
            {user.type >= 256 && <Menu title={<FormattedMessage id="INVOICE_ARCHIVE"/>} to="/invoice-stat" icon="fas fa-file-invoice"/>}

            <Menu title={<FormattedMessage id="MY_PROFILE"/>} to="/profile" icon="fas fa-user"/>
        </MenuGroup>


        {user.type === 512 && <MenuGroup title={<FormattedMessage id="Admin"/>}>
            {user.type === 512 && <Menu title={<FormattedMessage id="LANGUAGE_FEATURES"/>} to="/language" icon="fas fa-language"/>}
            {user.type === 512 && <Menu title={<FormattedMessage id="COUPONS"/>} to="/coupon" icon="fas fa-percent"/>}
            {user.type === 512 && <Menu title={<FormattedMessage id="MODULES"/>} to="/quiz" icon="fas fa-question-circle"/>}
            {user.type === 512 && <Menu title={<FormattedMessage id="OPTIONS"/>} to="/option-group" icon="fas fa-check-circle"/>}
        </MenuGroup>}
    </Sidebar>;
}
