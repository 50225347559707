import {useParams} from "react-router-dom";
import {useFetch} from "react-admin-base";
import {Actions, ActionsColumn, Column, BootstrapDataTable, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import {FormattedMessage} from "react-intl";
import FormattedDateTime from '../../Components/FormattedDateTime';
import React from "react";

const defaultParams = {
    sort: 'id',
    desc: true
};

export default function ResellerInvoices() {
    const { id } = useParams();
    const [ reseller ] = useFetch("/reseller/" + id);

    if (!reseller)
        return null;

    return <Breadcrumb
        title={reseller.name}
        data={
            [
                {
                    href: "/reseller",
                    name: <FormattedMessage id={"RESELLLERS"}/>
                },
                {
                    href: "/reseller/" + id + "/edit",
                    name: reseller.name
                },
                {
                    href: "/reseller/" + id + "/invoices",
                    name: <FormattedMessage id={"INVOICES"}/>
                },
            ]
        }
    >
        <BootstrapDataTable url={"/reseller/" + id + "/invoices"} defaultParams={defaultParams}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column className="min text-center" sort="count">User Count</Column>
                    <Column className="min text-center" sort="price">Price</Column>
                    <Column sort="created_at">Created at</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.count || '0'}</td>
                    <td className="min text-center">{row.price} &euro;</td>
                    <td><FormattedDateTime value={row.created_at} /></td>
                    <td>
                        <a className="btn btn-outline-primary" href={process.env.REACT_APP_ENDPOINT.replace(/\/$/, '') + row.invoice.file.access_url} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-file-invoice me-1" />
                            View invoice
                        </a>
                    </td>
            </tr>}
            />
        </BootstrapDataTable>
    </Breadcrumb
>;
}