import React from 'react';
import {Col, Input, Row, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, CheckBox} from 'react-admin-base-bootstrap';
import {Field} from "../../Components/Fields";
import {ResellerSelect} from "../../Components/Selects";
import {useIntl} from "react-intl";

export default function CouponEntity({url, onReload, id}) {
    const entity = useEntity('/coupon', id, { price: 500, active: true });
    const [data, setData] = entity;
    const intl = useIntl();

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title={intl.formatMessage({id: 'COUPON_ADMINISTRATION'})} url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Field translate="ACTIVE">
                <CheckBox
                    id="active"
                    checked={data.active}
                    onChange={a => setData({active: !data.active})}
                />
            </Field>
            <Field translate="CODE" type="required">
                <Input
                    type="text"
                    value={data.code || ''}
                    onChange={a => setData({code: a.currentTarget.value})}
                />
            </Field>
            <Field translate="NAME" type="required">
                <Input
                    type="text"
                    value={data.name || ''}
                    onChange={a => setData({name: a.currentTarget.value})}
                />
            </Field>
            <Row>
                <Col>
                    <Field translate="PRICE" type="required|numeric">
                        <Input
                            type="text"
                            value={data.price || ''}
                            onChange={a => setData({price: a.currentTarget.value})}
                            disabled
                        />
                    </Field>
                </Col>
                <Col>
                    <Field translate="RESELLER_PRICE" type="required|numeric">
                        <Input
                            type="text"
                            value={data.reseller_price || ''}
                            onChange={a => setData({reseller_price: a.currentTarget.value})}
                        />
                    </Field>
                </Col>
            </Row>
            <Field translate="RESELLER">
                <ResellerSelect
                    value={data.reseller}
                    onChange={a => setData({reseller: a})}
                />
            </Field>
        </ModalBody>
    </ModalEntityEditor>;
}
