import { Validator } from "react-admin-base-bootstrap";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {FormattedMessage} from "react-intl";
import React from "react";

export default function AddressInput({ prefix, value, onChange }) {
	prefix = prefix || '';

	return <>
		<Row>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="ZIP"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "zip"} type="required">
						<Input
							type="text"
							value={(value && value.zip) || ''}
							onChange={a => onChange({ ...(value || {}), zip: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="CITY"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "city"} type="required">
						<Input
							type="text"
							value={(value && value.city) || ''}
							onChange={a => onChange({ ...(value || {}), city: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="STREET"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "street"} type="required">
						<Input
							type="text"
							value={(value && value.street) || ''}
							onChange={a => onChange({ ...(value || {}), street: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Label><FormattedMessage id="HOUSE_NUMBER"/><sup><i className="fas fa-asterisk ms-1 text-danger field-required"></i></sup></Label>
					<Validator name={prefix + "house_no"} type="required">
						<Input
							type="text"
							value={(value && value.house_no) || ''}
							onChange={a => onChange({ ...(value || {}), house_no: a.currentTarget.value })}
						/>
					</Validator>
				</FormGroup>
			</Col>
		</Row>
	</>;
}