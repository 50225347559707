import React from 'react';
import {Card, CardBody, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {Breadcrumb} from 'react-admin-base-front';
import {EntityEditor, PasswordInput, Validator} from 'react-admin-base-bootstrap';
import {FormattedMessage, useIntl} from "react-intl";
import AddressInput from '../../Components/AddressInput';

export default function Edit() {
    const entity = useEntity('/user', 'me');
    const [data, setData, loading, save] = entity;
    
    const intl = useIntl();

    return <>
        <Breadcrumb
            data={
                [
                    {
                        href: '/profil',
                        name: <FormattedMessage id="USERS"/>
                    }
                ]
            }
        />
        <EntityEditor entity={entity}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="VORNAME"/></Label>
                        <Validator name="first_name" type="required">
                            <Input
                                type="text"
                                value={data.first_name || ''}
                                onChange={a => setData({first_name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label><FormattedMessage id="NACHNAME"/></Label>
                        <Validator name="last_name" type="required">
                            <Input
                                type="text"
                                value={data.last_name || ''}
                                onChange={a => setData({last_name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="EMAIL"/></Label>
                <Validator name="email" type="required">
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="PROFILE_BIRTHDAY"/></Label>
                <Validator name="email" type="required">
                    <Input type="date" value={data.birthday}
                           onChange={a => setData({birthday: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="text-input"><FormattedMessage id="PHONE"/></Label>
                <Validator name="phone" type="required">
                    <Input type="text" value={data.phone || ''}
                           onChange={a => setData({phone: a.currentTarget.value})}/>
                </Validator>
            </FormGroup>

<hr/>
            <FormGroup>
                <Label><FormattedMessage id="PROFILE_ADRESS"/></Label>
                <AddressInput prefix="user_" value={data.address} onChange={address => setData({ address })} />
            </FormGroup>

            <PasswordInput
                value={data.password || ''}
                onChange={a => setData({password: a})}
                required={false}
                placeholder={intl.formatMessage({ id: 'CHANGE_PASSWORD_PLACEHOLDER' })}
            />
        </EntityEditor>
    </>;
}
