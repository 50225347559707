
import React, {useCallback, useState} from 'react';
import { ApiSelect, Relative } from 'react-admin-base-bootstrap';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import OptionEntity from "../pages/Option/OptionEntry2";
import ResellerEntity from "../pages/Resellers/ResellerEntity";

export function CreateSelect(props) {
    const { Component, onChange, value } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const onReload = useCallback(function(data) {
        setIsOpen(false);
        data && onChange(data);
    }, [onChange, setIsOpen]);

    return <>
        { isOpen && <Component id={value && value.id} onReload={onReload} /> }
        <ApiSelect {...props} onAddOrEdit={() => setIsOpen(true)} />
    </>;
}

export function OptionSelect(props) {
    return <CreateSelect
        url="/option-group"
        Component={OptionEntity}
        {...props}
    />;
}

export function OptionSelectPortal({ value, setValue, options }) {
    const intl = useIntl();
    return <Input type="select" value={(+value&&value.toString())||''} onChange={a => setValue(+a.currentTarget.value)}>
        <option value="">{ intl.formatMessage({ id: 'PLEASE_SELECT'}) }</option>
        {
            options.values
            .map(a => <option key={a.id} value={a.id.toString()}>
                {a.name}
            </option>)
        }
    </Input>;
}

export function LevelSelect({ value, onChange, className }) {
    const intl = useIntl();

    return <Input type="select" className={className} value={(value || '').toString()} onChange={a => onChange(+a.currentTarget.value)}>
        <option>{ intl.formatMessage(({ id: 'PLEASE_SELECT' })) }</option>
        <option value="40">{ intl.formatMessage(({ id: 'LEVEL_1' })) }</option>
        <option value="30">{ intl.formatMessage(({ id: 'LEVEL_2' })) }</option>
        <option value="25">{ intl.formatMessage(({ id: 'LEVEL_3' })) }</option>
    </Input>;
}

export function SpauseStatusSelect({ value, onChange, className }) {
    const intl = useIntl();

    return <Input type="select" className={className} value={((value === false ? "false" : value) || '').toString()} onChange={a => onChange(a.currentTarget.value)}>
        <option>{ intl.formatMessage(({ id: 'PLEASE_SELECT' })) }</option>
        <option value="false">{ intl.formatMessage(({ id: 'ALONE' })) }</option>
        <option value="true">{ intl.formatMessage(({ id: 'PARTNER' })) }</option>
    </Input>;
}

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function CouponSelect(props) {
    return <ApiSelect url="/coupon" {...props}>
        { row => <>
            { row.name } <br />
            <small><b>{row.code}</b> | {row.reseller_price}&euro;</small>
        </>}
    </ApiSelect>;
}

export function ResellerSelect(props) {
    return <CreateSelect
        url="/reseller"
        Component={ResellerEntity}
        {...props}
    />;
}
