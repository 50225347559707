import { useCallback, useMemo, useState } from "react";
import Swal from "sweetalert2";

export class PaymentActionRequiredError extends Error {
	constructor(obj) {
		super(); // (1)
		this.name = "PaymentActionRequired"; // (2)
		this.data = obj;
	}
}

export function useOnSuccess(submit, message) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ done, setDone ] = useState(false);
	
	const onSubmit = useCallback(async function(data) {
        let err = false;

		Swal.showLoading();
		setLoading(true);
		setError(null);
		try {
			await submit(data);
			
            message && await Swal.fire('Erfolg', message, 'success');
		} catch(e) {
			if (e.response && e.response.status === 402) {
				throw new PaymentActionRequiredError(e.response.data);
			}
			
			console.error(e);
			setError(e);
            Swal.fire('Error', e.response && e.response.data.message || 'An error occured. Please try again.', 'error');
            err = true;
		} finally {
			setLoading(false);
            if (!err) Swal.close();
		}
	}, [submit, message]);
	
	return useMemo(() => [onSubmit, loading, error, done], [onSubmit, loading, error, done]);
}
