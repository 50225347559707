
import React, { useState } from "react";
import { useFetch, ValidatorProvider } from "react-admin-base";
import { SingleFilePicker, useDataTableContext } from "react-admin-base-bootstrap";
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useForm } from "../../Components/useForm";
import {FormattedMessage} from "react-intl";

export function UserPaymentApproveButton({ id }) {
	const [ isOpen, setIsOpen ] = useState(false);
	
	return <td className="min text-center">
        { isOpen && <UserPaymentApproveModal id={id} onClose={() => setIsOpen(false)} /> }
		<Button  type="button" color="success" className="mr-1 align-middle" outline  onClick={a => setIsOpen(true)}>	<FormattedMessage id="PAYMENT_APPROVE_BUTTON"/></Button>
	</td>;
}

function UserPaymentApproveModal({ id, onClose }) {
	const [ session ] = useFetch('/user/' + id);
	const [ , setParams ] = useDataTableContext();
	
	const [ onSubmit,, error, loading ] = useForm(async function (api) {
		const ret = await api.tokenized.post('/user/' + id + '/approve-payment');
		onClose();
		setParams({});
		return ret;
	}, [ id, onClose ]);
	
	const [ onReject,, error2, loading2 ] = useForm(async function (api) {
		const data = await api.tokenized.post('/user/' + id + '/reject-payment');
		onClose();
		setParams({});
		return data;
	}, [ id, onClose ]);
	
	if (!session)
		return null;
	
	return <Modal size="lg" isOpen toggle={onClose}>
		<ModalHeader toggle={onClose}>
			User Approval
		</ModalHeader>
		<ValidatorProvider>
			<Form onSubmit={onSubmit} disabled={loading || loading2}>
				<ModalBody>
					<SingleFilePicker
						value={session.payment_file}
						onChange={_ => {}}
						disabled
					/>
					
					<Row className="w-100 mt-3">
						<Col>
							<Button type="submit" color="success" block loading={loading}>
								<FormattedMessage id="PAYMENT_APPROVE"/>
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Form>
		</ValidatorProvider>
	</Modal>;
}
