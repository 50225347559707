import {Actions, ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import ResellerEntity from "./ResellerEntity";
import {FormattedMessage} from "react-intl";
import { Routes, Route, Link } from 'react-router-dom';
import React from "react";
import ResellerInvoices from './ResellerInvoices';
import {useUser} from "../../Components/UserProvider";

export default function Resellers() {
    const defaultParams = {
        sort: 'id',
        desc: true
    };

    return <Routes>
        <Route path=":id/invoices" element={<ResellerInvoices />} />
        <Route path="*" element={
            <Breadcrumb
                data={
                    [
                        {
                            href: "/reseller",
                            name: <FormattedMessage id={"RESELLLERS"}/>
                        }
                    ]
                }
                >
                <CRUD url="/reseller" defaultParams={defaultParams} Component={ResellerEntity}>
                    <thead>
                        <tr>
                            <IdColumn/>
                            <Column sort="name">Name</Column>
                            <Column sort="manager_name"><FormattedMessage id="MANAGER_NAME"/></Column>
                            <Column className="min" sort="coupon_count"><FormattedMessage id="COUPON_COUNT"/></Column>
                            <Column className="min" sort="coupon_usage_count"><FormattedMessage id="COUPON_USAGE_COUNT"/></Column>
                            <ActionsColumn/>
                        </tr>
                    </thead>
                    <RowRenderer
                        render={row => <tr key={row.id}>
                            <td className="min text-center">{row.id}</td>
                            <td>{row.name}</td>
                            <td>{row.manager_name}</td>
                            <td className="text-center">{row.coupon_count}</td>
                            <td className="text-center">{row.coupon_usage_count}</td>
                            <CRUDActions
                                id={row.id}
                                edit
                                del={"/reseller/" + row.id}
                            >
                                <Link to={row.id + "/invoices"} className="btn btn-outline-primary ms-1">
                                    <i className="fas fa-file-invoice me-1" />
                                    <FormattedMessage id={"INVOICES"}/>
                                </Link>
                            </CRUDActions>
                    </tr>}
                    />
                </CRUD>
            </Breadcrumb>}
        />
    </Routes>;
}
