import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-front';
import CouponEntity from "./CouponEntity";
import {FormattedMessage} from "react-intl";
import React from "react";

export default function Coupons() {
    const defaultParams = {
        sort: 'id',
        desc: true
    };

    return <Breadcrumb
        data={
            [
                {
                    href: "/coupon",
                    name: <FormattedMessage id={"COUPONS"}/>
                }
            ]
        }
    >
        <CRUD url="/coupon" defaultParams={defaultParams} Component={CouponEntity}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="active" className="min"><FormattedMessage id="ACTIVE"/></Column>
                <Column sort="code" className="min text-center"><FormattedMessage id="CODE"/></Column>
                <Column sort="name"><FormattedMessage id="NAME"/></Column>
                <Column sort="reseller"><FormattedMessage id="RESELLER"/></Column>
                <Column className="min" sort="usage_count"><FormattedMessage id="TOTAL_USAGE"/></Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.active ? 'Yes' : 'No'}</td>
                    <td className="min text-center">{row.code}</td>
                    <td>{row.name}</td>
                    <td>{row.reseller?.name || '-'}</td>
                    <td className="text-center">{row.usage_count || '-'}</td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/coupon/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
