import React from 'react';
import {Input, Label,  Table, ModalBody, FormGroup, Button} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {useUser} from "../../Components/UserProvider";
import {ModalEntityEditor, Validator} from 'react-admin-base-bootstrap';

function Options({ value, onChange }) {
    return <Table hover striped>
        <thead>
            <tr>
                <th className="min text-right">#</th>
                <th>Name</th>
                <th>Name (Report)</th>
                <th style={{ width: '15%' }}>Formula</th>
                <th className="min text-center">Nummer fragen</th>
                <th className="min text-center">Aktionen</th>
            </tr>
        </thead>
        <tbody>
            <MultiValue value={value} add onChange={onChange}>
                {
                    (row, onChange, index) => <tr key={index}>
                        <td className="min text-right">{ index + 1 }.</td>
                        <td>
                            <Input
                                type="text"
                                placeholder="Name"
                                value={(row && row.name) || ''}
                                onChange={a => onChange({ ...(row || {}), name: a.currentTarget.value })}
                            />
                        </td>
                        <td>
                            <Input
                                type="text"
                                placeholder="Name (Report)"
                                value={(row && row.name_backend) || ''}
                                onChange={a => onChange({ ...(row || {}), name_backend: a.currentTarget.value })}
                            />
                        </td>
                        <td>
                            <Input
                                disabled={!row}
                                type="text"
                                placeholder="Formula"
                                value={(row && row.formula) || ''}
                                onChange={a => onChange({ ...(row || {}), formula: a.currentTarget.value })}
                            />
                        </td>
                        <td className="text-center">
                            <Input
                                disabled={!row}
                                id={"check_"+index}
                                type="checkbox"
                                checked={(row && row.ask_number) || false}
                                onClick={a => onChange({ ...(row || {}), ask_number: !row.ask_number })}
                            />
                        </td>
                        <td>
                            { row && <Button type="button" block color="danger" outline onClick={a => onChange(null)}>
                                <i className="fas fa-trash" />
                            </Button> }
                        </td>
                    </tr>
                }
            </MultiValue>
        </tbody>
    </Table>;
}


export default function OptionEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/option-group', id, { pages: [{}] });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="lg" title="Option Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Label>Name der Option</Label>
                <Validator name="name" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Validator>
            </FormGroup>
            <Options
                value={data.values}
                onChange={values => setData({ values })}
            />
        </ModalBody>
    </ModalEntityEditor>;
}
