import React from 'react';
import {MultiValue, useAuth, useFetch} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker, Validator} from "react-admin-base-bootstrap";
import {Button, Input, ModalBody, Table} from "reactstrap";
import {useUser} from "../../Components/UserProvider";

import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";

const FILE_TYPES = [
    'BETREUERAUSWEIS',
    'VOLLMACHT_LARS_KOENIG',
    'VOLLMACHT_FUER_DEN_RECHTANWALT',
    'PHOTO_HOCHLADEN',
    'MDK_GUTACHTEN_HOCHLANDE',
    'PFLEGEGELDBESCHEID_HOCHLADEN',
    'ANDERE_DATEI_HOCHLADEN'
];

function FileTypeSelect({ value, onChange }) {
    const intl = useIntl();
    
    return <Input type="select" value={value || ''} onChange={a => onChange(a.currentTarget.value || null)}>
        <option value="">{intl.formatMessage({ id: 'PLEASE_SELECT' })}</option>
        { FILE_TYPES.map((a,i) => <option value={i + 1}>{ intl.formatMessage({ id: a }) }</option>) }
    </Input>
}

export default function AttachmentsEntity() {
    const {id} = useParams();
    const user = useUser();
    const entity = useAttachmentsEntity(id);
    const [session] = useFetch('/session/' + id);
    const [data, setData] = entity;
    const intl = useIntl();

    if (!data || !session) {
        return null;
    }

    return <ModalEntityEditor size="xl" title={intl.formatMessage({id: 'SESSION_ATTACHMENTS'})} url="/session" onReload={() => {
    }} entity={entity}>
        <ModalBody>
            <h2>{session.user.first_name} {session.user.last_name} | <FormattedMessage id="SESSION_FILES"/></h2>
            <h3 className="mt-4">Benutzer hochgeladen </h3>

            <Table>
                <tbody>
                    <MultiValue value={data} onChange={setData}>
                        {(row, onChange, index) => <tr key={index}>
                            <td style={{ width: '400px' }}>
                                <Validator name={"file." + index + ".type"} type={row ? "required" : ""}>
                                    <FileTypeSelect
                                        value={row && row.type}
                                        onChange={a => onChange({ type: a, file: (row && row.file) || null })}
                                    />
                                </Validator>
                            </td>
                            <td>
                                <SingleFilePicker
                                    value={row && row.file}
                                    onChange={a => onChange({ type: (row && row.type) || null, file: a })}
                                    disabled={!!(row && row.file)}
                                />
                            </td>
                            {user.type >= 128 && <td>
                                {row && <Button type="button" color="danger" onClick={_ => onChange(null)}><i className="fas fa-trash"/></Button>}
                            </td>}
                        </tr>}
                    </MultiValue>
                </tbody>
            </Table>
            
            <h3 className="pt-4">System generiert </h3>
            <div>
                    {session.vollmacth_pdf_link && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + session.vollmacth_pdf_link} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                        <i className="fas fa-file-pdf"/> Vollmacht
                    </a>}
                    {session.rechtsanwalt_pdf_link && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + session.rechtsanwalt_pdf_link} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                        <i className="fas fa-file-pdf"/> Vollmacht für den Rechtsanwalt
                    </a>}
                    {!session.user?.receipt && session.user?.invoice && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + session.user.invoice.file.access_url} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                        <i className="fas fa-file-pdf"/> Proformarechnung
                    </a>}
                    {session.user?.receipt && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + session.user.receipt.file.access_url} className="btn m-1 btn-outline-primary btn-sm" target="_blank">
                        <i className="fas fa-file-pdf"/> Rechnung
                    </a>}
                    {<a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + "/agb.pdf"} className="btn m-1 btn-outline-primary" target="_blank">
                        <i className="fas fa-file-pdf"/> AGB
                    </a>}
                    {<a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + "/datenschutzerklarung.pdf"} className="btn m-1 btn-outline-primary" target="_blank">
                        <i className="fas fa-file-pdf"/> Datenschutzerklärung
                    </a>}
            </div>
        </ModalBody>
    </ModalEntityEditor>;
}

function useAttachmentsEntity(id) {
    const [api] = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const update = useCallback(async function () {
        setLoading(true);
        try {
            const response = await api.tokenized.get("/session/" + id + "/files");
            setData(response.data || []);
        } finally {
            setLoading(false);
        }
    }, [api, data, loading, id]);

    const save = useCallback(async function () {
        setLoading(true);
        try {
            var response;

            response = await api.tokenized.patch("/session/" + id + "/files", data);

            setData(response.data);
            return response.data;
        } finally {
            setLoading(false);
        }
    }, [api, data, loading, id]);

    useEffect(function () {
        update();
    }, [id]);

    return [
        data,
        setData,
        save,
        loading
    ];
}
