import { Breadcrumb} from 'react-admin-base-front';
import OptionEntity from "./OptionEntry2";
import {Actions, ActionsColumn, CRUD, Column, IdColumn, RowRenderer } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';

export default function Options() {
    return <Breadcrumb
        title="Optionen"
        data={
            [
                {
                    href: '/option',
                    name: <FormattedMessage id="OPTIONS"/>
                }
            ]
        }
    >
        <CRUD url="/option-group" Component={OptionEntity}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Name der Option</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                        <td className="min text-center">{row.id}</td>
                        <td>{row.name}</td>
                        <Actions
                            edit={"/option-group/" + row.id + '/edit'}
                        />
                    </tr>
                }
            />
        </CRUD>
    </Breadcrumb>;
}
