import { useCallback, useReducer } from "react";
import { useRefresh } from "react-admin-base";
import { Button } from "reactstrap";
import { useUser } from "./UserProvider";

export default function InlineEditBtn({ Component, id, className }) {
	const [ show, toggle ] = useReducer(a => !a, false);
	const update = useRefresh();
	
	const reload = useCallback(function() {
		update();
		toggle();
	}, [ toggle, update ]);
	
	return <>
		{ show && <Component id={id} onReload={reload} /> }
		<Button type="button" size="sm" outline color="success" className={className} onClick={toggle}>
			<i className="fas fa-pencil" /> Edit
		</Button>
	</>;
}

export function InlineEditLink({ Component, id, className, children }) {
	const [ show, toggle ] = useReducer(a => !a, false);
	const update = useRefresh();
	
	const onToggle = useCallback(function (e) {
		e.preventDefault();
		toggle();
	}, [toggle]);
	
	const reload = useCallback(function() {
		update();
		toggle();
	}, [ toggle, update ]);
	
	const user = useUser();
	if (user.type <= 8)
		return children;
	
	return <>
		{ show && <Component id={id} onReload={reload} /> }
		<a href="#" onClick={onToggle}>
			{ children }
		</a>
	</>;
}
