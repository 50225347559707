import {useCallback, useMemo, useRef} from 'react'
import {PayPalButton} from 'react-paypal-button-v2'

export default function PayPal({ onSubmit }) {
    const paymentReference = useRef();

    const options = useMemo(() => ({
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: 'EUR'
    }), []);

    const createOrder = useCallback(async function () {

        try {
            try {
                await onSubmit();
            } catch (e) {
                if (e.name === 'PaymentActionRequired') {
                    paymentReference.current = e.data;
                    return e.data;
                }
                throw e;
            }

            throw new Error('createOrder error');
        } finally {
            
        }
    }, [paymentReference, onSubmit]);

    const onApprove = useCallback(async function () {
        try {
            await onSubmit({
                paypal_order_id: paymentReference.current
            });
        } finally {
            
        }
    }, [paymentReference, onSubmit]);

    return <div>
        <PayPalButton
            options={options}
            createOrder={createOrder}
            onApprove={onApprove}
        />
    </div>;
}
