import React, {useCallback, useState} from 'react';
import {Card, CardBody, Col, Input, Label, Row, ListGroup, Table, ListGroupItem, ModalBody, FormGroup, Button} from "reactstrap";
import {MultiValue, useEntity} from "react-admin-base";
import {useUser} from "../../Components/UserProvider";
import Orderable, {Orderable2} from "../../Components/Orderable";
import {LanguageSelect, OptionSelect} from "../../Components/Selects";
import {CKEditor, CKEditorWithUpload} from 'react-admin-base-ckeditor';
import {ModalEntityEditor, SingleFilePicker, Validator} from 'react-admin-base-bootstrap';

function Page({value, onChange}) {
    return <div>
        <Row>
            <Col>
                <FormGroup>
                    <Label>Seitentitel</Label>
                    <Input
                        type="text"
                        value={value.title || ''}
                        onChange={a => onChange({...value, title: a.currentTarget.value})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Untertitel</Label>
                    <Input
                        type="text"
                        value={value.subtitle || ''}
                        onChange={a => onChange({...value, subtitle: a.currentTarget.value})}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <Label>Seitentitel (report)</Label>
                    <Input
                        type="text"
                        value={value.title_backend || ''}
                        onChange={a => onChange({...value, title_backend: a.currentTarget.value})}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Untertitel (report)</Label>
                    <Input
                        type="text"
                        value={value.subtitle_backend || ''}
                        onChange={a => onChange({...value, subtitle_backend: a.currentTarget.value})}
                    />
                </FormGroup>
            </Col>
        </Row>
        <FormGroup>
            <Label>Seitenbeschreibung</Label>
            <CKEditorWithUpload
                value={value.description || ''}
                onChange={a => onChange({...value, description: a})}
            />
        </FormGroup>
        <FormGroup>
            <Label>Feste Minut</Label>
            <Input
                type="number"
                value={value.fixed_score || ''}
                onChange={a => onChange({...value, fixed_score: (+a.currentTarget.value) || null})}
            />
        </FormGroup>
        <Table>
            <thead>
            <tr>
                <th className="min text-right">#</th>
                <th>Frage</th>
                <th>Frage (Report)</th>
                <th className="text-center" style={{width: '15%'}}>Anzahl pro Tag</th>
                <th className="text-center" style={{width: '15%'}}>Minuten</th>
                <th className="min text-center">Aktionen</th>
            </tr>
            </thead>
            <tbody>
            <MultiValue value={value.questions || []} add onChange={val => onChange({...value, questions: val})}>
                {
                    (row, onChange, index) => <React.Fragment key={index}>
                        <tr>
                            <td rowSpan={3} className="min text-right">{index + 1}.</td>
                            <td>
                                <Input
                                    type="text"
                                    placeholder="Question"
                                    value={(row && row.question) || ''}
                                    onChange={a => onChange({...(row || {}), question: a.currentTarget.value})}
                                />
                            </td>
                            <td>
                                <Input
                                    type="text"
                                    placeholder="Question (report)"
                                    value={(row && row.question_backend) || ''}
                                    onChange={a => onChange({...(row || {}), question_backend: a.currentTarget.value})}
                                />
                            </td>
                            <td>
                                <Input
                                    disabled={!row}
                                    type="number"
                                    placeholder="Number"
                                    value={(row && row.number1) || ''}
                                    onChange={a => onChange({...(row || {}), number1: a.currentTarget.value})}
                                />
                            </td>
                            <td>
                                <Input
                                    disabled={!row}
                                    type="number"
                                    placeholder="Number"
                                    value={(row && row.number2) || ''}
                                    onChange={a => onChange({...(row || {}), number2: a.currentTarget.value})}
                                />
                            </td>
                            <td rowSpan={3}>
                                {row && <Button type="button" block color="danger" outline onClick={a => onChange(null)}>
                                    <i className="fas fa-trash"/>
                                </Button>}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <OptionSelect
                                    value={(row && row.option_group)}
                                    onChange={a => onChange({...(row || {}), option_group: a})}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <CKEditor
                                    disabled={!row}
                                    value={(row && row.description) || ''}
                                    onChange={a => onChange({...(row || {}), description: a})}
                                />
                            </td>
                        </tr>
                    </React.Fragment>
                }
            </MultiValue>
            </tbody>
        </Table>
    </div>;
}

function PageModule({value, onChange, extras}) {
    const [index, setIndex] = useState(0);
    const [extraIndex, setExtraIndex] = useState(-1);

    const addPage = useCallback(function () {
        onChange(value.concat([{}]));
        setIndex(value.length);
        setExtraIndex(-1);
    }, [value, onChange, setIndex]);

    const question = value[index];
    const setQuestion = useCallback(function (val) {
        onChange(value.map((a, i) => i === index ? val : a));
    }, [index, value, onChange]);

    const onChangePage = useCallback(function (newValue) {
        const index = newValue.indexOf(question);
        if (index !== -1) {
            setIndex(index);
            setExtraIndex(-1);
        }

        onChange(newValue);
    }, [value, onChange, question]);

    return <Row>
        <Col md="2">
            <div className="page-list">
                <Orderable value={value} onChange={onChangePage}>
                    {
                        (val, idx) => <div className={"page-list-entry" + (extraIndex === -1 && idx === index ? " active" : "")} onClick={() => {
                            setIndex(idx);
                            setExtraIndex(-1);
                        }}>
                            <Row>
                                <Col>
                                    Seite {idx + 1}
                                </Col>
                                {value.length > 1 && <Col md="auto">
                                    <i className="fas fa-trash text-danger" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChange(value.filter(a => a !== val));
                                        if (index === value.length - 1) {
                                            setIndex(index - 1);
                                        }
                                    }}/>
                                </Col>}
                            </Row>
                        </div>
                    }
                </Orderable>
            </div>
            <Button type="button" className="mt-2" color="primary" outline size="sm" block onClick={addPage}>Seite hinzufügen</Button>
            {
                extras.map((a, i) => <Button key={i}
                                             type="button"
                                             className="mt-2"
                                             color="primary"
                                             outline={extraIndex !== i}
                                             size="sm"
                                             block
                                             onClick={() => {
                                                 setExtraIndex(extraIndex === i ? -1 : i);
                                             }}>{a.title}</Button>
                )
            }
        </Col>
        {extraIndex === -1 && question && <Col>
            <h4>Page {index + 1}.</h4>
            <Page
                key={index}
                value={question}
                onChange={setQuestion}
            />
        </Col>}
        {extraIndex !== -1 && extras[extraIndex].value}
    </Row>;
}

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/quiz', id, {lang_code: 'de', pages: [{}]});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Modul-Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <Row>
                <Col md="9">
                    <FormGroup>
                        <Label>Name der Module</Label>
                        <Validator name="name" type="required">
                            <Input
                                type="text"
                                value={data.name}
                                onChange={a => setData({name: a.currentTarget.value})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label>Übersetzungssprache</Label>
                        <Validator name="lang_code" type="required">
                            <LanguageSelect
                                value={data.language}
                                onChange={code => setData({language: code})}
                            />
                        </Validator>
                    </FormGroup>
                </Col>
            </Row>
            <PageModule
                value={data.pages}
                onChange={pages => setData({pages})}
                extras={[
                    {
                        title: "Anhänge",
                        value: <QuizAttachments
                            value={data.attachments}
                            onChange={attachments => setData({attachments})}
                        />
                    }
                ]}
            />
        </ModalBody>
    </ModalEntityEditor>;
}

function QuizAttachments({value, onChange}) {
    return <Col>
        <Table hover striped>
            <thead>
            <tr>
                <th style={{width: '20%'}}>Min. Minuten</th>
                <th>Datei</th>
                <th>Hinweis</th>
            </tr>
            </thead>
            <tbody>
            <MultiValue add value={value} onChange={onChange}>
                {
                    (row, onChange, index) => <tr key={index}>
                        <td>
                            <Validator name={"Nummer #" + index} type={row == null ? null : "required|numeric"}>
                                <Input
                                    type="number"
                                    value={(row && row.min_score) || ''}
                                    onChange={a => onChange(a.currentTarget.value.length === 0 ? null : {...(row || {}), min_score: a.currentTarget.value})}
                                />
                            </Validator>
                        </td>
                        <td>
                            <Validator name={"File #" + index} type={row == null ? null : "required"}>
                                <SingleFilePicker
                                    value={(row && row.file) || ''}
                                    onChange={a => onChange({...(row || {}), file: a})}
                                />
                            </Validator>
                        </td>
                        <td>
                            <Input
                                type="text"
                                value={(row && row.note) || ''}
                                onChange={a => onChange({...(row || {}), note: a.currentTarget.value})}
                            />
                        </td>
                    </tr>
                }
            </MultiValue>
            </tbody>
        </Table>
    </Col>;
}
