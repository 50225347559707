import { useReducer } from "react";
import { useFetch } from "react-admin-base";
import { FormattedMessage } from "react-intl";
import { Navigate, useParams } from "react-router-dom";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { AnswersTable } from "./SessionEntity";

export default function BedafsrechnerPage() {
	const { id } = useParams();
	const [ isOpen, toggleIsOpen ] = useReducer(a => !a, true);
	const [ data ] = useFetch('/session/' + id);
	
	if (!isOpen)
		return <Navigate to="/session" />;
	
	return <Modal size="xl" isOpen toggle={toggleIsOpen}>
		<ModalHeader toggle={toggleIsOpen}>
			Bedafsrechner
		</ModalHeader>
		{data && data.answers && <AnswersTable answers={data.answers} /> }
		<ModalFooter>
			<Button type="button" block color="danger" outline onClick={toggleIsOpen}>
				<FormattedMessage id="CLOSE" />
			</Button>
		</ModalFooter>
	</Modal>;
}