import React from 'react';
import {MultiValue, useAuth, useFetch} from "react-admin-base";
import {ModalEntityEditor, Validator} from "react-admin-base-bootstrap";
import {Button, FormGroup, Input, Label, ModalBody, Table} from "reactstrap";
import {useUser} from "../../Components/UserProvider";

import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import AssistantPlanTitleSelect from '../../Components/AssistantPlanTitleSelect';


function AssistantTable({ value, onChange }) {
    return <Table striped hover className="mb-0">
        <thead>
            <tr>
                <th className="min">#</th>
                <th><FormattedMessage id="VORNAME" /></th>
                <th><FormattedMessage id="NACHNAME" /></th>
                <th><FormattedMessage id="ADRESS" /></th>
                <th><FormattedMessage id="JOB" /></th>
                <th />
            </tr>
        </thead>
        <tbody>
            <MultiValue value={value} onChange={onChange}>
                { (row, onChange, index) => <tr key={index}>
                    <td style={{ verticalAlign: 'middle' }}>{ index + 1 }.</td>
                    <td>
                        <Validator name={"Name."+index} type={row ? "required" : ""}>
                            <Input
                                type="text"
                                value={(row && row.first_name) || ''}
                                onChange={a => onChange({ ...(row || {}), first_name: a.currentTarget.value })}
                            />
                        </Validator>
                    </td>
                    <td>
                        <Validator name={"LastName."+index} type={row ? "required" : ""}>
                            <Input
                                type="text"
                                value={(row && row.last_name) || ''}
                                onChange={a => onChange({ ...(row || {}), last_name: a.currentTarget.value })}
                            />
                        </Validator>
                    </td>
                    <td>
                        <Validator name={"Address."+index} type={row ? "required" : ""}>
                            <Input
                                type="text"
                                value={(row && row.address) || ''}
                                onChange={a => onChange({ ...(row || {}), address: a.currentTarget.value })}
                            />
                        </Validator>
                    </td>
                    <td>
                        <Validator name={"Position."+index} type={row ? "required" : ""}>
                            <Input
                                type="text"
                                value={(row && row.position) || ''}
                                onChange={a => onChange({ ...(row || {}), position: a.currentTarget.value })}
                            />
                        </Validator>
                    </td>
                    <td>
                        { row && <Button type="button" outline color="danger" onClick={() => onChange(null)}>
                            <i className="fas fa-trash" />
                        </Button> }
                    </td>
                </tr> }
            </MultiValue>
        </tbody>
    </Table>;
}


export default function AssistantPlanEntity() {
    const {id} = useParams();
    const user = useUser();
    const entity = useAsistantEntity(id);
    const [session] = useFetch('/session/' + id);
    const [data, setData] = entity;

    if (!data || !session) {
        return null;
    }

    return <ModalEntityEditor size="xl" title={"Assistant Plan #"+id} url="/session" onReload={() => {
    }} entity={entity}>
    
        <ModalBody>
            <h3 className="text-center"><FormattedMessage id="ASSISTANT_PLAN_FOR"/> {session.user.first_name} {session.user.last_name}</h3>
            <p className="text-center">
                { session.user.address && <>Adresse: {session.user.address.city}, {session.user.address.street}, {session.user.address.house_no}, {session.user.address.zip}</> }
                <br />Rufnummer: {session.user.phone} | E-mail: {session.user.email} | Geburtstag:  {session.user.birthday}</p>
        </ModalBody>
        
        <hr className="my-0"/>
        <ModalBody className="pb-0">
            <Table className="mb-0">
                <tbody>
                    <MultiValue value={data.assistant_plans || []} onChange={assistant_plans => setData({ ...data, assistant_plans })}>
                        {(row, onChange, index) => <tr key={index}>
                            <td className="min">
                                <FormGroup>
                                    <Label><FormattedMessage id="ASSISTANT_PLAN_TIME"/></Label>
                                    <Validator name={"time." + index} type={row ? "required" : ""}>
                                        <Input
                                            type="time"
                                            value={(row && row.time) || ''}
                                            onChange={a => onChange({...row, time: a.currentTarget.value})}
                                        />
                                    </Validator>
                                </FormGroup>
                            </td>
                            <td>
                                <FormGroup>
                                    <Label><FormattedMessage id="ASSISTANT_PLAN_NAME"/></Label>
                                    <Validator name={"event_name." + index} type={row ? "required" : ""}>
                                        <AssistantPlanTitleSelect
                                            value={(row && row.event_name) || ''}
                                            onChange={a => onChange({...row, event_name: a})}
                                        />
                                    </Validator>
                                </FormGroup>
                            </td>
                            <td>
                                <FormGroup>
                                    <Label><FormattedMessage id="ASSISTANT_PLAN_DETAIL"/></Label>
                                    <Input
                                        type="text"
                                        value={(row && row.event_detail) || ''}
                                        onChange={a => onChange({...row, event_detail: a.currentTarget.value})}
                                    />
                                </FormGroup>
                            </td>
                            <td>
                                {row && <Button type="button" color="danger" onClick={_ => onChange(null)}><i className="fas fa-trash"/></Button>}
                            </td>
                        </tr>}
                    </MultiValue>
                </tbody>
            </Table>
        </ModalBody>
        <hr className="my-0"/>
        <h3 className="text-center my-3"><FormattedMessage id="ASSISTANTS" /></h3>
        <AssistantTable
            value={data.assistants || []}
            onChange={assistants => setData({ ...data, assistants })}
        />
    </ModalEntityEditor>;
}

function useAsistantEntity(id) {
    const [api] = useAuth();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const update = useCallback(async function () {
        setLoading(true);
        try {
            const response = await api.tokenized.get("/session/" + id + "/assistant-plan");
            setData(response.data || []);
        } finally {
            setLoading(false);
        }
    }, [api, data, loading, id]);

    const save = useCallback(async function () {
        setLoading(true);
        try {
            var response;

            response = await api.tokenized.patch("/session/" + id + "/assistant-plan", data);

            setData(response.data);
            return response.data;
        } finally {
            setLoading(false);
        }
    }, [api, data, loading, id]);

    useEffect(function () {
        update();
    }, [id]);

    return [
        data,
        setData,
        save,
        loading
    ];
}
