import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { useFetch, useAuth } from 'react-admin-base';
import { useUser } from '../../Components/UserProvider';

function useEventSource(url) {
	const [ ev, setEv ] = useState();
	
	useEffect(function() {
		if (url) {
			const _ev = new EventSource(url);
			setEv(_ev);
			return function() {
				_ev.close();
			};
		}
	}, [url, setEv]);
	
	return ev;
}

const EventSourceContext = React.createContext();

function EventSourceProvider({ children, url }) {
	const ev = useEventSource(url);
	return <EventSourceContext.Provider value={ev}>
		{ children }
	</EventSourceContext.Provider>;
}

export function ChatWrapper({ children }) {
	const [ ticket ] = useFetch('/message/ticket');
	
	if (!ticket)
		return null;
	
	return <EventSourceProvider url={process.env.REACT_APP_ENDPOINT + ticket}>
		{children}
	</EventSourceProvider>
}

function useEventMessageEffect(id, cb, deps) {
	const handler = useCallback(cb, deps);
	const ev = useContext(EventSourceContext);
	
	useEffect(function() {
		if (ev && handler) {
			ev.addEventListener(id, handler);
			
			return function() {
				ev.removeEventListener(id, handler);
			};
		}
	}, [handler, ev, id]);
}

export default function useChat(sessionId) {
	const [ api ] = useAuth();
	const [ messages, setMessages ] = useState([]);

	useEffect(function() {
		async function update() {
			const res = await api.tokenized.get('/session/' + sessionId + '/message');
			setMessages(res.data.data);
		}

		update();
	}, [api, setMessages, sessionId]);
	
	useEventMessageEffect('message', function(e) {
		var message = JSON.parse(e.data);
		console.log(message);
		
		if (message.sessionId == sessionId) {
			setMessages(a => [message].concat(a));
		}
	}, [setMessages, sessionId]);

	return messages;
}
