import React, {useCallback, useReducer, useRef} from "react";
import {Button, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import {useFetch} from 'react-admin-base';
import {AssistantTable} from "./SessionEntity";
import {useUser} from '../../Components/UserProvider';

var groupBy = function (arr, criteria) {
    return arr.reduce(function (obj, item) {

        // Check if the criteria is a function to run on the item or a property of it
        var key = typeof criteria === 'function' ? criteria(item) : item[criteria];

        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }

        // Push the value to the object
        obj[key].push(item);

        // Return the object to the next item in the loop
        return obj;

    }, {});
};


export default function SessionTableButton({id, pdfLink, status}) {
    const [showPrint, toggleShowPrint] = useReducer(a => !a, false);
    const user = useUser();

    return <td className="min text-center">
        <Button type="button" color="primary" className="mr-1 align-middle" outline={!showPrint} onClick={toggleShowPrint}>
            Wochenplanung
        </Button>
        {pdfLink && <a href={process.env.REACT_APP_ENDPOINT?.replace(/\/$/g, '') + pdfLink} className="btn ms-1 btn-outline-primary" target="_blank">
            <i className="fas fa-file-pdf"/>
        </a>}
        {
            showPrint && <Modal size="lg" isOpen toggle={toggleShowPrint}>
                <ModalHeader className="text-center" toggle={toggleShowPrint}>
                    <b>Antrag #{id}</b>
                </ModalHeader>
                <ModalBody>
                    <SessionTable
                        id={id}
                    />
                </ModalBody>
            </Modal>
        }
    </td>;
}

export function SessionTable({id}) {
    const ref = useRef();
    const [session] = useFetch('/session/' + id);
    const user = useUser();

    const print = useCallback(function () {
        const win = window.open('about:blank', '', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000');

        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerHTML = `
            @media print{ @page {size: landscape; margin: 0mm;} }
            .comma > span:not(:last-child)::after { content: ', '; }
            body { padding: 5mm; }
            h3, h4 { text-align: center; }
            button { display: none }
            table { border-collapse: collapse; width: 100%; }
            td, th { border: solid 1px #000; padding: 5px 5px; }
            .min { width: 20%; }
            .min2 { width: 1% !important; }
        `;

        win.document.head.appendChild(styleSheet);

        win.document.body.innerHTML = ref.current.innerHTML;
        win.print();
        win.close();
    }, [ref]);

    if (!session)
        return null;

    return <div ref={ref}>
        <h3 className="text-center">Individulle Wochenplanung / Hilfeplanung für {session.user.first_name} {session.user.last_name}</h3>

        <p className="text-center">
            { session.user.address && <>Adresse: {session.user.address.city}, {session.user.address.street}, {session.user.address.house_no}, {session.user.address.zip}</> }
            <br />Rufnummer: {session.user.phone} | E-mail: {session.user.email} | Geburtstag: {session.user.birthday}</p>
        <h4 className="text-center">(7 Tage) {((session.score / 60 * 7).toFixed(2))} std</h4>
        <hr/>
        <Table hover bordered>
            <thead>
            <tr>
                <th className="min-wrap"/>
                <th className="min-wrap text-center">Themenfelder</th>
                <th className="text-center">Benötigt Hilfe bei:</th>
            </tr>
            </thead>
            <tbody>
            {
                Object.entries(groupBy(session.answers, a => a.question.page.order + "|||" + (a.question.page.title_backend || '') + "|||" + (a.question.page.subtitle_backend || '')))
                    .map(a => [a[0].split("|||"), a[1]])
                    .sort((a, b) => +a[0][0] - +b[0][0])
                    .map(([[index, title, subtitle], answers]) =>
                        <tr>
                            <td className="min-wrap">
                                <b>{title}</b>
                            </td>
                            <td className="min-wrap">
                                {subtitle}
                            </td>
                            <td className="comma">
                                {
                                    answers
                                        .filter(a => a.score > 0)
                                        .map(a => <span>
                                                <b>{a.question.question_backend}:</b> {a.option.name_backend}{(a.number && <> ({a.number})</>) || false}
                                            </span>)
                                }
                            </td>
                        </tr>
                    )
            }
            </tbody>
        </Table>
        <div className="mt-3">
            <h3 className="text-center">Assistenten</h3>
            <AssistantTable value={session.assistants}/>
        </div>
        {user.type >= 128 && <Button type="button" color="primary" outline block onClick={print}><i className="fas fa-print"/></Button>}
    </div>;
}
