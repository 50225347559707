
import React from 'react';
import { useCallback, useState } from 'react';
import {Alert, Button, Form, FormGroup, Label } from 'reactstrap';
import {FormattedMessage} from "react-intl";

export default function Zahlung({ onSubmit }) {
    const [ file, setFile ] = useState(null);
    
    const onApprove = useCallback(async function (e) {
        try {
            await onSubmit({
                payment_file: file
            });
        } finally {
            
        }
    }, [file, onSubmit]);

    return <>
        <Button className="mt-5" color="primary" block type="button" onClick={onApprove}><FormattedMessage id="SEND_PAYMENT_PROOF"/></Button>
    </>;
}
