import './App.scss';

import {
    App,
    Authorized,
    AuthProvider,
    ForgotPasswordRoute,
    LoginRoute,
    NotAuthorized,
    RegisterRoute
} from 'react-admin-base';
import {DefaultValidatorOptions} from 'react-admin-base-bootstrap';
import {Login, MainLayout, Reset} from 'react-admin-base-front';

import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./Components/UserProvider";
import UploadConfig from "./UploadConfig";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LanguageProvider from './LanguageProvider';
import SessionGuard from './Components/SessionGuard';
import { Fragment } from 'react';
import FirstSession from './pages/Session/FirstSession';
import Register from './pages/Register';
import ValidatorSettings from "./ValidatorSettings";

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id="pflegekreuz"
                name="Pflegekreuz"
                endpoint={process.env.REACT_APP_ENDPOINT}
                logo={(process.env.REACT_APP_PATHBASE || "") + '/logo.png'}
                onlylogo={(process.env.REACT_APP_PATHBASE || "") + '/logo-simple.png'}
            >
                <AuthProvider
                    tokenEndpoint="/token"
                    client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                    client_secret="123456"
                >
                    <LanguageProvider>
                        <ValidatorSettings>
                        <UploadConfig>
                            <DefaultValidatorOptions>
                                <NotAuthorized>
                                    <LoginRoute>
                                        <Login/>
                                    </LoginRoute>
                                    <RegisterRoute>
                                        <Register />
                                    </RegisterRoute>
                                    <ForgotPasswordRoute>
                                        <Reset/>
                                    </ForgotPasswordRoute>
                                </NotAuthorized>
                                <Authorized>
                                    <UserProvider>
                                            <Routes>
                                                <Route path="/session/first" element={<div className="register-page">
                                                    <MainLayout>
                                                        <MenuHeader isFromSession />
                                                        <Fragment />
                                                        <FirstSession />
                                                    </MainLayout>
                                                </div>} />
                                                <Route path="*" element={<SessionGuard>
                                                    <MainLayout>
                                                        <MenuHeader/>
                                                        <MenuSidebar/>
                                                        <Router/>
                                                        <Footer/>
                                                    </MainLayout>
                                                </SessionGuard>} />
                                            </Routes>
                                    </UserProvider>
                                </Authorized>
                            </DefaultValidatorOptions>
                        </UploadConfig>
                        </ValidatorSettings>
                    </LanguageProvider>
                </AuthProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
